import React, { useState } from "react";

import video_call_pic from "../AlIimage/vidcall11.jpg";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const VideoCall = () => {
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState({
    name: "",
    phone: "",
    date: "",
    time: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
    console.log(data);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      axios
        .post(`/api/video-call/request/new`, data)
        .then((res) => {
          console.log(res);
          toast.success("Request sent successfully");
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  return (
    <div style={{ width: "100vw", overflowX: "hidden" }}>
      <div
        className="container p-5"
        style={{
          maxWidth: "900px",
        }}
      >
        <div className="row p-4">
          <div
            className="card custom-border "
            style={{ boxShadow: "5px 5px 5px 5px #285979" }}
          >
            <div
              className="card-body p-3"
              style={{
                backgroundImage: `url(${video_call_pic})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "right",
                objectFit: "cover",
                backgroundSize: "cover" /* Add this line */,
              }}
            >
              <h1 style={{ color: "#fff" }}>Video Call</h1>
              <form>
                <div className="form-row">
                  <div className="form-group col-md-8  ">
                    <input
                      type="text"
                      className="form-control mt-3 "
                      id="name"
                      name="name"
                      placeholder="Name"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group col-md-8  ">
                    <input
                      type="phone"
                      className="form-control mt-3 mb-3"
                      id="phone"
                      name="phone"
                      maxLength={10}
                      placeholder="Phone number"
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="form-group col-md-8  ">
                  <label style={{ color: "white", fontWeight: "bold" }}>
                    Date:
                  </label>
                  <input
                    type="date"
                    className="form-control mt-1 "
                    id="date"
                    name="date"
                    style={{ color: "black" }}
                    min={new Date().toISOString().split("T")[0]}
                    placeholder="Date"
                    onChange={handleChange}
                  />
                  <label style={{ color: "white", fontWeight: "bold" }}>
                    Time:
                  </label>
                  <input
                    type="time"
                    className="form-control mt-1 mb-3"
                    id="time"
                    name="time"
                    style={{ color: "black" }}
                    onChange={handleChange}
                    placeholder="Time"
                  />
                  <button
                    style={{
                      padding: "12px",
                      background: "#285979",
                      color: "#fff",

                      border: "none",
                      cursor: "pointer",
                      borderRadius: "10px",
                    }}
                    type="submit"
                    className="btn btn-submit mt-3 "
                    onClick={handleSubmit}
                    disabled={loading}
                  >
                    Submit
                  </button>
                  <ToastContainer />
                </div>
              </form>
            </div>
          </div>
          <div className="mt-5">
            <span
              className="text-center"
              style={{
                color: "#285979",
                fontFamily: "Raleway",
                fontSize: "1.5rem",
              }}
            >
              Any Questions? Please feel free to reach us at :{" "}
            </span>
            <span style={{ color: "#285979", fontSize: "1.5rem" }}>
              <b>+91-9100006969</b>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoCall;
