import React, { useState } from "react";
import book_appoinment_pic from "../AlIimage/book_appoinment_pic.png";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const BookAppointment = () => {
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState({
    name: "",
    phone: "",
    date: "",
    time: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
    console.log(data);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      axios
        .post(`/api/video-call/request/new`, data)
        .then((res) => {
          console.log(res);
          toast.success("Request sent successfully");
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  return (
    <div
      style={{
        backgroundImage: `url(${book_appoinment_pic})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "right",
      }}
    >
      <div className="">
        <div className="row">
          <div className="col-md-7">
            <div className="">
              <div className="card-body">
                <h3 className="primary-text">Book Appointment</h3>
                <form>
                  <div className="form-group m-2">
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      name="name"
                      onChange={handleChange}
                      placeholder="Name"
                    />
                  </div>
                  <div className="form-group m-2">
                    <input
                      type="text"
                      className="form-control"
                      id="phone"
                      name="phone"
                      maxLength={10}
                      onChange={handleChange}
                      placeholder="Phone number"
                    />
                  </div>
                  <div className="form-group m-2">
                    <input
                      type="date"
                      className="form-control"
                      id="date"
                      name="date"
                      min={new Date().toISOString().split("T")[0]}
                      onChange={handleChange}
                      placeholder="Date"
                    />
                  </div>
                  <div className="form-group m-2">
                    <input
                      type="time"
                      className="form-control"
                      id="time"
                      name="time"
                      onChange={handleChange}
                      placeholder="Time"
                    />
                  </div>
                  <div
                    className="row"
                    style={{
                      justifyContent: "center",
                    }}
                  >
                    <button
                      type="submit"
                      className="btn btn-submit mt-2 "
                      onClick={handleSubmit}
                      disabled={loading}
                      style={{ borderRadius: "10px", background: "#285979" }}
                    >
                      Book Now
                    </button>
                    <ToastContainer />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookAppointment;
