import React from "react";
import neck from "../AlIimage/neck.png";
import makingHistory from "../AlIimage/makingHistory.svg";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Stamp from "../AlIimage/Stamp.png";
import Container from "react-bootstrap/Container";
import fproduct3 from "../AlIimage/fproduct3.png";
import values from "../AlIimage/values.png";
import legacy from "../AlIimage/legacy.png";
import craftsman from "../AlIimage/craftsman.png";

const OurLegacy = () => {
  return (
    <div>
      <div
        className="our-legacy"
        style={{
          backgroundColor: "#FFFFFF",
          backgroundRepeat: "no-repeat",
          backgroundSize: "auto",
          backgroundPositionX: "center",
          // paddingBottom: "5%",
          width: "100%",
        }}
      >
        <div style={{ marginTop: "-1rem" }}>
          <div className="row">
            <div className="col-sm ">
              <span className="title">About Us</span>
            </div>
          </div>
        </div>

        {/* Value , Legacy Text and images*/}
        {/*
      <table
        align="center"
        cellSpacing={0}
        cellPadding={0}
        style={{
          tableLayout: "fixed",
          width: "95%",
          margin: "3rem",

          borderCollapse: "collapse",
        }}
      >
        <tr>
          <td style={{ width: "33.33%", padding: "3rem", height: "100%" }}>
            <tr style={{ height: "50%" }}>
              <td>
                {" "}
                <img src={fproduct3} width="100%" alt="..."></img>
              </td>
            </tr>
            <tr style={{ height: "50%" }}>
              <td style={{ verticalAlign: "middle" }}>
                <div className="">
                  <p
                    className="container-title"
                    style={{
                      fontSize: "40px",
                      marginBottom: "1rem",
                      marginTop: "3rem",
                      textAlign: "center",
                    }}
                  >
                    Our Values
                  </p>
                  <p
                    className="small"
                    style={{
                      display: "table-cell",
                      verticalAlign: "middle",
                      textAlign: "center",
                    }}
                  >
                    Capturing the visual essence of Indian culture in its
                    intricate designs, Jatin Mor jewels are carefully crafted
                    giving life to the stones and the metal. Jewellery at Jatin
                    Mor is an embodiment of finesse and exceptional
                    craftsmanship.
                  </p>
                </div>
              </td>
            </tr>
          </td>

          <td style={{ width: "33.33%", padding: "3rem", height: "100%" }}>
            <tr style={{ height: "50%" }}>
              <td style={{ verticalAlign: "middle" }}>
                {" "}
                <div className="">
                  <p
                    style={{
                      fontSize: "40px",
                      marginBottom: "1rem",
                      marginTop: "0.5rem",
                      textAlign: "center",
                    }}
                  >
                    Our Legacy
                  </p>
                  <p
                    className="small"
                    style={{
                      display: "table-cell",
                      verticalAlign: "middle",
                      textAlign: "center",
                    }}
                  >
                    The history of jewellery making at Jatin Mor dates back to
                    the eighteenth century. Risen in the land of beauty and
                    affluence, the Zenab of Nizams, Jatin Mor jewellers
                    inculcates in itself, the rich history and cultural
                    influence of Hyderabad.
                  </p>
                </div>
              </td>
            </tr>
            <tr style={{ height: "50%" }}>
              <td>
                <img src={fproduct3} width="100%" alt="..."></img>
              </td>
            </tr>
          </td>

          <td style={{ width: "33.33%", padding: "3rem", height: "100%" }}>
            <tr style={{ height: "50%" }}>
              <td>
                <img src={fproduct3} width="100%" alt="..."></img>
              </td>
            </tr>
            <tr style={{ height: "50%" }}>
              <td style={{ verticalAlign: "middle" }}>
                {" "}
                <div className="">
                  <p
                    style={{
                      fontSize: "40px",
                      marginBottom: "1rem",
                      marginTop: "0.5rem",
                      textAlign: "center",
                    }}
                  >
                    The Craftsmen
                  </p>
                  <p
                    className="small"
                    style={{
                      display: "table-cell",
                      verticalAlign: "middle",
                      textAlign: "center",
                    }}
                  >
                    Craftsmanship at Jatin Mor is exquisite and elegant. Witness
                    the finest quality of time-defying jewellery made from a
                    harmonious amalgamation of modern and antique designs. Every
                    piece of jewellery at Jatin Mor is an ode to skilful
                    craftsmanship and ethereal designs. Through the years, Jatin
                    Mor has been involved in creating alluring pieces of
                    jewellery. The expertise, skill and the spirit of artisanal
                    excellence are seen from initial inspirational sketch to the
                    final designs.
                  </p>
                </div>
              </td>
            </tr>
          </td>
        </tr>
      </table>
                  */}

        <div style={{ marginTop: "-1rem" }} className="container">
          <div className="grid m-0 p-0">
            <div className="item">
              <img
                className="img-fluid"
                style={{ margin: "0rem 1rem" }}
                src={values}
                alt="..."
              />
            </div>
            <div className="item">
              <a>
                <div className="text-wrapper" style={{}}>
                  <p
                    className="small"
                    style={{
                      fontWeight: "500px",
                      color: "#285979",
                      margin: "0rem 1rem",
                      fontFamily: "Raleway",
                      verticalAlign: "center",
                      textAlign: "center",
                    }}
                  >
                    Craftsmanship at Jatin Mor is exquisite and elegant. Witness
                    the finest quality of time-defying jewellery made from a
                    harmonious amalgamation of modern and antique designs. Every
                    piece of jewellery at Jatin Mor is an ode to skilful
                    craftsmanship and ethereal designs. Through the years, Jatin
                    Mor has been involved in creating alluring pieces of
                    jewellery. The expertise, skill and the spirit of artisanal
                    excellence are seen from initial inspirational sketch to the
                    final designs.
                  </p>
                </div>
              </a>
            </div>
            <div className="item">
              <img
                className="img-fluid"
                style={{ margin: "0rem 1rem" }}
                src={craftsman}
              />
            </div>
            <div className="item">
              <a>
                {" "}
                <div className="text-wrapper">
                  <p
                    className="small"
                    style={{
                      fontWeight: "500",
                      margin: "0rem 1rem",
                      color: "#285979",
                      fontFamily: "Raleway",
                      verticalAlign: "middle",
                      textAlign: "center",
                    }}
                  >
                    Capturing the visual essence of Indian culture in its
                    intricate designs, Jatin Mor jewels are carefully crafted
                    giving life to the stones and the metal. Jewellery at Jatin
                    Mor is an embodiment of finesse and exceptional
                    craftsmanship.
                  </p>
                </div>
              </a>
            </div>
            {/* <div className="item">
              {" "}
              <div className="text-wrapper">
                <p
                  className="small"
                  style={{
                    fontWeight: "500px",
                    // paddingTop: "33%",
                    margin: "0rem 1rem",
                    fontFamily: "Playfair Display",
                    verticalAlign: "middle",
                    textAlign: "center",
                  }}
                >
                  Capturing the visual essence of Indian culture in its
                  intricate designs, Jatin Mor jewels are carefully crafted
                  giving life to the stones and the metal. Jewellery at Jatin
                  Mor is an embodiment of finesse and exceptional craftsmanship.
                </p>
              </div>
            </div> */}
            <div className="item">
              {" "}
              <img
                className="img-fluid"
                style={{ margin: "0rem 1rem" }}
                src={legacy}
                alt="legacy"
              />
            </div>
            <div className="item">
              <a>
                {" "}
                <div className="text-wrapper">
                  <p
                    className="small"
                    style={{
                      fontWeight: "500",
                      margin: "0rem 1rem",
                      color: "#285979",
                      fontFamily: "Raleway",
                      verticalAlign: "middle",
                      textAlign: "center",
                    }}
                  >
                    The history of jewellery making at Jatin Mor dates back to
                    the eighteenth century. Risen in the land of beauty and
                    affluence, the Zenab of Nizams, Jatin Mor jewellers
                    inculcates in itself, the rich history and cultural
                    influence of Hyderabad.
                  </p>
                </div>
              </a>
            </div>
          </div>
        </div>

        {/* <div
        className="container mt-4"
        style={{
          display: "grid",
          gridTemplateColumns: "auto auto auto",
          gridTemplateRows: "auto",
          justifyContent: "space-between",
          alignItems: "stretch",
          height: "100dvh",
        }}
      >
        <div className="row text-center">
          <div className="col-sm mt-2 text-col" style={{}}>
            <div className="col-sm">
              <img src={fproduct3} width="80%" alt="..."></img>
              <div className="text-wrapper">
                <p
                  className="container-title"
                  style={{
                    fontSize: "40px",
                    marginBottom: "1rem",
                    marginTop: "3rem",
                    textAlign: "center",
                  }}
                >
                  Our Values
                </p>
                <p
                  className="small"
                  style={{
                    display: "table-cell",
                    verticalAlign: "middle",
                    textAlign: "center",
                  }}
                >
                  Capturing the visual essence of Indian culture in its
                  intricate designs, Jatin Mor jewels are carefully crafted
                  giving life to the stones and the metal. Jewellery at Jatin
                  Mor is an embodiment of finesse and exceptional craftsmanship.
                </p>
              </div>
            </div>
          </div>

          <div className="col-sm mt-2 text-col" style={{}}>
            <div className="text-wrapper">
              <p
                style={{
                  fontSize: "40px",
                  marginBottom: "1rem",
                  marginTop: "0.5rem",
                  textAlign: "center",
                }}
              >
                Our Legacy
              </p>
              <p
                className="small"
                style={{
                  display: "table-cell",
                  verticalAlign: "middle",
                  textAlign: "center",
                }}
              >
                The history of jewellery making at Jatin Mor dates back to the
                eighteenth century. Risen in the land of beauty and affluence,
                the Zenab of Nizams, Jatin Mor jewellers inculcates in itself,
                the rich history and cultural influence of Hyderabad.
              </p>
            </div>
            <img src={fproduct3} width="80%" alt="..."></img>
          </div>

          <div className="col-sm text-col" style={{}}>
            <div className="col-sm mt-2">
              <img src={fproduct3} width="80%" alt="..."></img>
              <div className="text-wrapper">
                <p
                  style={{
                    fontSize: "40px",
                    marginBottom: "1rem",
                    marginTop: "0.5rem",
                    textAlign: "center",
                  }}
                >
                  The Craftsmen
                </p>
                <p
                  className="small"
                  style={{
                    display: "table-cell",
                    verticalAlign: "middle",
                    textAlign: "center",
                  }}
                >
                  Craftsmanship at Jatin Mor is exquisite and elegant. Witness
                  the finest quality of time-defying jewellery made from a
                  harmonious amalgamation of modern and antique designs. Every
                  piece of jewellery at Jatin Mor is an ode to skilful
                  craftsmanship and ethereal designs. Through the years, Jatin
                  Mor has been involved in creating alluring pieces of
                  jewellery. The expertise, skill and the spirit of artisanal
                  excellence are seen from initial inspirational sketch to the
                  final designs.
                </p>
              </div>
            </div>{" "}
          </div>
        </div>
      </div>
                */}

        {/* Neck full b/2 image*/}
      </div>

      {/***
      <div
        className="mt-5 p-4"
        style={{ position: "relative", display: "inline", top: "-35px" }}
      >
        <img
          className="float-end me-5"
          style={{ position: "absolute", top: "", right: "15%" }}
          src={Stamp}
          alt=""
          width="20%"
        />
        <img
          className=""
          src={neck}
          alt=""
          width="100%"
          style={{ padding: "0 10%", top: "" }}
        />
      </div>
      ** */}
    </div>
  );
};

export default OurLegacy;
