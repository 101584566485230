import React, { useEffect, useState } from "react";
import saving_scheme_banner_2 from "../AlIimage/web necklace copy.jpg";
import how_it_works from "../AlIimage/hww.jpg";
import playStore from "../AlIimage/playstore.png";
import apple from "../AlIimage/apple.png";

import FormRange from "react-bootstrap/FormRange";
import FrequentlyAsked from "./FrequentlyAsked";
import { Chart } from "react-google-charts";
import pieChart from "../AlIimage/pie chart.png";
import divider from "../AlIimage/page breaker.png";
import { useMediaQuery } from "react-responsive";

const SavingsScheme = () => {
  const [amount, setAmount] = useState(1000);
  const [discount, setDiscount] = useState(0);
  const [chartData, setChartData] = useState([]);
  const isSmallScreen = useMediaQuery({ maxWidth: 600 });

  // set the data for the chart here youPay as 11*amount and Discount as 1*amount using amount state variables
  const setChartDataFunc = () => {
    //clear the chartData array
    setChartData([]);
    //set the chartData array
    const data = [
      [`You Pay}`, `Discount`],
      ["You Pay", 11 * amount],
      ["Discount", 1 * amount],
    ];

    const newChartData = [
      [`You Pay}`, `Discount`, { role: "annotation" }],
      ...data
        .slice(1)
        .map(([label, value]) => [label, value, value.toString()]),
    ];
    setChartData(newChartData);
  };
  useEffect(() => {
    setChartDataFunc();
  }, []);

  const handleCheck = (e) => {
    e.preventDefault();
    if (amount > 0) {
      setChartDataFunc();
    }
  };

  const options = {
    pieHole: 0.4,
    is3D: false,
    width: "300px",

    backgroundColor: "transparent",
    colors: ["#285979", "#3187bd"],
    annotations: {
      alwaysOutside: true,
      textStyle: {
        fontSize: 20,
        color: "black",
        auraColor: "none",
      },
    },

    animation: {
      duration: 1000,
      easing: "out",
      startup: true,
    },
  };

  const handleChange = (e) => {
    const value = e.target.value;
    // Replace any non-digit character with an empty string
    const sanitizedValue = value.replace(/[^0-9]/g, "");
    setChartDataFunc();
    setAmount(sanitizedValue);
  };

  return (
    <div style={{ width: "100vw", overflowX: "hidden" }}>
      {/**********swana soubhagya scheme */}
      <div>
        <span
          className="title"
          style={{ marginTop: "1.5rem", padding: 0 }}
          // className="text-center container-title mt-2 p-0"
          // style={{
          //   fontSize: "36px",
          //   fontWeight: "bolder",
          //   marginBottom: 0,
          //   fontFamily: "Felix Titling",
          //   color: "#285979",
          // }}
        >
          Swarna Soubhagya <br /> Savings Scheme
        </span>

        <div className="container">
          <div className="row">
            <div>
              <div className="item">
                {!isSmallScreen && (
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <h6
                      style={{
                        fontSize: "1.8rem",
                        // fontFamily: "Raleway",
                        marginTop: 0,
                        fontWeight: "bold",
                        color: "#285979",
                      }}
                    >
                      GET STARTED NOW
                    </h6>
                    <p
                      style={{
                        fontSize: "1.5rem",
                        textAlign: "center",
                        // fontFamily: "Raleway",
                        marginTop: 0,
                        fontWeight: "bold",
                        color: "#285979",
                      }}
                    >
                      Download our app
                    </p>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src={playStore}
                        style={{ width: "50px" }}
                        alt="playstore"
                        onClick={() =>
                          window.open(
                            "https://play.google.com/store/apps/details?id=com.dsoft.morjewellers"
                          )
                        }
                      />
                      <img
                        src={apple}
                        alt="apple"
                        onClick={() =>
                          window.open(
                            "https://apps.apple.com/in/app/jatin-mor/id6469781402"
                          )
                        }
                        style={{ width: "50px" }}
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className="item">
                {/* <img
                  src={saving_scheme_banner_2}
                  alt=""
                  width={"300px"}
                  style={{ maxWidth: "300px" }}
                /> */}
              </div>
              <div
                className="item"
                style={{
                  // verticalAlign: "middle",
                  fontSize: "1.5rem",
                  fontFamily: "Raleway",
                  display: "flex",
                  flexDirection: "column",
                  // height: "100%",
                  textAlign: "left",
                }}
              >
                <p
                  style={{
                    fontSize: "2.5rem",
                    fontWeight: "bolder",
                    textAlign: "left",
                    color: "#285979",
                    fontFamily: "Raleway",
                  }}
                >
                  11+1
                </p>

                <p
                  style={{
                    fontWeight: "bold",
                    borderTop: "1px solid grey",
                    color: "#285979",
                    fontFamily: "Raleway",
                  }}
                >
                  Monthly Installment Plan
                </p>
              </div>
            </div>
            <div className="text-center">
              <p
                style={{
                  fontSize: "24px",
                  color: "#285979",
                  fontFamily: "Raleway",
                }}
              >
                Pay 11 installments, get <b>100% OFF</b> on 12<sup>th</sup>{" "}
                installment
              </p>
            </div>
            {isSmallScreen && (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <p
                  style={{
                    fontWeight: "bold",
                    fontSize: "1.5rem",
                    margin: "0rem",
                    color: "#285979",
                    fontFamily: "Raleway",
                    textAlign: "center",
                  }}
                >
                  GET STARTED NOW
                </p>
                <p
                  style={{
                    color: "#285979",
                    fontFamily: "Raleway",
                    textAlign: "center",
                    // fontSize: isSmallScreen ? "1rem" : "1.5rem",
                    width: "100%",
                    fontSize: "24px",

                    // background: "black",
                  }}
                >
                  Download our app
                </p>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <img
                    src={playStore}
                    style={{ width: "50px" }}
                    alt="playstore"
                    onClick={() =>
                      window.open(
                        "https://play.google.com/store/apps/details?id=com.dsoft.morjewellers"
                      )
                    }
                  />
                  <img
                    src={apple}
                    alt="apple"
                    onClick={() =>
                      window.open(
                        "https://apps.apple.com/in/app/jatin-mor/id6469781402"
                      )
                    }
                    style={{ width: "50px" }}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <img
          src={divider}
          width="100%"
          alt="divider "
          className="divider2"
        ></img>
      </div>

      {/***Why swarna sobhagya **** */}

      {/***How it works **** */}
      <div style={{ background: "#ffffff" }}>
        <span
          className="title"
          // style={{
          //   color: "#285979",
          //   fontSize: "24px",
          //   fontFamily: "Felix Titling",
          //   fontWeight: "bold",
          // }}
          style={{ paddingTop: "2rem" }}
        >
          How it works?
        </span>
        <div
          className="container"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={how_it_works}
            alt=""
            width={isSmallScreen ? "100%" : "80%"}
          />
        </div>
      </div>
      {/*******Gold Mine Calulator */}
      <div style={{ background: "#FFFFFF" }}>
        {!isSmallScreen && (
          <span
            className="title"
            // style={{
            //   color: "#285979",
            //   fontSize: "24px",
            //   fontFamily: "Felix Titling",
            //   fontWeight: "bold",
            // }}
          >
            Gold Mine Calculator
          </span>
        )}
        <div className="container">
          <div className="row">
            <div className="col-md-6 ">
              <div style={{ margin: "0rem 1rem" }}>
                <h6
                  style={{
                    fontSize: isSmallScreen ? "1.5rem" : "3rem",
                    // fontFamily: "Raleway",
                    textAlign: isSmallScreen ? "center" : "",
                    marginTop: 0,
                    fontFamily: "Felix Titling",

                    color: "#285979",
                  }}
                >
                  {" "}
                  WHY{" "}
                </h6>
                <p
                  style={{
                    fontSize: isSmallScreen ? "1rem" : "3rem",
                    // fontFamily: "Raleway",
                    marginTop: 0,
                    padding: 0,
                    margin: 0,

                    textAlign: isSmallScreen ? "center" : "",
                    // fontWeight: "bold",
                    color: "#285979",
                    fontFamily: "Felix Titling",
                  }}
                >
                  Swarna Soubhagya Scheme
                </p>
                {/* <div className="grid">
                  <div className="item">
                    <div className="m-4 ml-0">
                      <i
                        className="fas fa-rupee-sign "
                        style={{ color: "#285979" }}
                      ></i>
                    </div>
                    <div style={{ textAlign: "left", fontFamily: "Raleway" }}>
                      <p
                        style={{
                          fontFamily: "Raleway",
                          fontWeight: "semibold",
                          color: "#285979",
                          margin: "0",
                        }}
                      >
                        Plan Ahead
                      </p>
                      <p style={{ color: "#285979", fontFamily: "Raleway" }}>
                        Subscribe to plan value for your future high value
                        purchases
                      </p>
                    </div>
                  </div>
                  <div className="item">
                    <div className="m-4">
                      <i
                        className="fas fa-gift"
                        style={{ color: "#285979" }}
                      ></i>
                    </div>
                    <div style={{ textAlign: "left" }}>
                      <p
                        style={{
                          color: "#285979",
                          margin: "0",
                          fontFamily: "Raleway",
                        }}
                      >
                        For Special Moments
                      </p>
                      <p style={{ color: "#285979", fontFamily: "Raleway" }}>
                        Plan for gifting on special occasion like Birthdays,
                        Weddings etc
                      </p>
                    </div>
                  </div>
                  <div className="item">
                    <div className="m-4">
                      <i
                        className="fa fa-bullseye"
                        style={{ color: "#285979" }}
                      ></i>
                    </div>
                    <div style={{ textAlign: "left" }}>
                      <p
                        style={{
                          fontFamily: "Raleway",
                          fontWeight: "semibold",
                          color: "#285979",
                          margin: "0",
                        }}
                      >
                        Special Discounts
                      </p>
                      <p style={{ color: "#285979", fontFamily: "Raleway" }}>
                        Pay 11 installments & get 100% discount on the 12th
                        installment
                      </p>
                    </div>
                  </div>
                </div> */}
                <div
                  // className="flex-container"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    rowGap: "1rem",
                    marginTop: "1rem",
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ margin: "0rem 1rem 1rem 0rem" }}>
                      <i
                        className="fas fa-rupee-sign "
                        style={{ color: "#285979" }}
                      ></i>
                    </div>
                    <div style={{ textAlign: "left", fontFamily: "Raleway" }}>
                      <p
                        style={{
                          fontFamily: "Raleway",
                          fontWeight: "semibold",
                          color: "#285979",
                          fontSize: isSmallScreen ? "1rem" : "1.5rem",
                          margin: "0",
                        }}
                      >
                        Plan Ahead
                      </p>
                      <p
                        style={{
                          color: "#285979",
                          fontFamily: "Raleway",
                          fontSize: isSmallScreen ? "1rem" : "1.5rem",
                        }}
                      >
                        Subscribe to plan value for your future high value
                        purchases
                      </p>
                    </div>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ margin: "0rem 1rem 1rem 0rem" }}>
                      <i
                        className="fas fa-gift"
                        style={{ color: "#285979" }}
                      ></i>
                    </div>
                    <div style={{ textAlign: "left" }}>
                      <p
                        style={{
                          color: "#285979",
                          margin: "0",
                          fontSize: isSmallScreen ? "1rem" : "1.5rem",
                          fontFamily: "Raleway",
                        }}
                      >
                        For Special Moments
                      </p>
                      <p
                        style={{
                          color: "#285979",
                          fontFamily: "Raleway",
                          fontSize: isSmallScreen ? "1rem" : "1.5rem",
                        }}
                      >
                        Plan for gifting on special occasion like Birthdays,
                        Weddings etc
                      </p>
                    </div>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ margin: "0rem 1rem 1rem 0rem" }}>
                      <i
                        className="fa fa-bullseye"
                        style={{ color: "#285979" }}
                      ></i>
                    </div>
                    <div style={{ textAlign: "left" }}>
                      <p
                        style={{
                          fontFamily: "Raleway",
                          fontWeight: "semibold",
                          color: "#285979",
                          margin: "0",
                          fontSize: isSmallScreen ? "1rem" : "1.5rem",
                        }}
                      >
                        Special Discounts
                      </p>
                      <p
                        style={{
                          color: "#285979",
                          fontFamily: "Raleway",
                          fontSize: isSmallScreen ? "1rem" : "1.5rem",
                          width: "100%",
                          // background: "black",
                        }}
                      >
                        Pay 11 installments & get 100% discount on the 12th
                        installment
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6  mt-2">
              <form className="form-inline    ">
                {isSmallScreen && (
                  <span
                    className="title"
                    // style={{
                    //   color: "#285979",
                    //   fontSize: "24px",
                    //   fontFamily: "Felix Titling",
                    //   fontWeight: "bold",
                    // }}
                  >
                    Gold Mine Calculator
                  </span>
                )}
                <p
                  style={{
                    fontFamily: "Raleway ",
                    margin: "0rem 1rem",
                    color: "#285979",
                  }}
                >
                  Slide or enter monthly installment amount
                </p>
                <label
                  htmlFor="amount mr-2"
                  style={{
                    margin: "0rem 1rem",
                    fontFamily: "Raleway",
                    color: "#285979",
                  }}
                >
                  Monthly Amount
                </label>
                <div className="form-group d-flex ">
                  <div
                    className="col-md-6"
                    style={{ marginRight: "1rem", marginLeft: "1rem" }}
                  >
                    <input
                      type="number"
                      min={0}
                      className="form-control "
                      value={amount}
                      pattern="[0-9]+"
                      onChange={handleChange}
                    />
                  </div>

                  <div className="col-md-4">
                    <button
                      className="btn btn-submit"
                      style={{ color: "#fff", background: "#285979" }}
                      onClick={handleCheck}
                    >
                      check
                    </button>
                  </div>
                </div>
              </form>
              <div className="col-md-6 p-2">
                <FormRange
                  className="range-slider "
                  min={0}
                  max={100000}
                  step={100}
                  onChange={handleChange}
                  value={amount}
                />
              </div>

              <div style={{ position: "relative", marginBottom: "3rem" }}>
                <div
                  style={{
                    position: "absolute",

                    left: "50%",
                    top: "20%",
                    // width: "100%",
                    transform: "translateX(-50%)",
                    // backgroundColor: "#010d1b",
                    color: "white",
                    padding: "8px",
                    // fontWeight: "bold",
                    fontSize: "1.5rem",

                    display: "flex",
                    flexDirection: "column",
                    textAlign: "center",
                  }}
                >
                  <span
                    style={{ margin: 0, padding: 0, fontFamily: "Raleway" }}
                  >
                    You pay
                  </span>
                  <span style={{ margin: 0, padding: 0, marginTop: "-0.7rem" }}>
                    {amount * 11}
                  </span>
                </div>
                <div
                  style={{
                    position: "absolute",

                    left: "50%",
                    top: "90%",
                    bottom: "0",
                    // width: "100%",
                    transform: "translateX(-50%)",
                    // backgroundColor: "#010d1b",
                    color: "#285979",
                    padding: "8px",
                    // fontWeight: "bold",
                    fontSize: "1.5rem",

                    display: "flex",
                    flexDirection: "column",

                    textAlign: "center",
                  }}
                >
                  <span
                    style={{ margin: 0, padding: 0, fontFamily: "Raleway" }}
                  >
                    You get
                  </span>
                  <span style={{ margin: 0, padding: 0, marginTop: "-0.7rem" }}>
                    {amount}
                  </span>
                </div>

                <div
                  className="item"
                  style={{
                    flex: "1 0 30%",
                    display: "flex",
                    marginBottom: "8px",
                  }}
                >
                  <img
                    src={pieChart}
                    alt={pieChart}
                    style={{
                      width: "300px",
                      height: "300px",
                      // objectFit: "cover",
                    }}
                  />
                </div>
              </div>
              <div className="mt-2">
                <div className="col-md-12  d-flex">
                  <div
                    className="col-md-8"
                    style={{ marginLeft: "-1rem", fontFamily: "Raleway" }}
                  >
                    <ul className="legend primary-text">
                      <li>
                        <span className=" paynow"></span>
                        Your total payment
                        <br />
                        <small>(period of 11 months)</small>
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-4 col-sm-2 primary-text">
                    {amount > 0 ? (
                      <p className="text-center">
                        <span className="text-center">
                          <b>₹ {amount * 11}</b>
                        </span>
                      </p>
                    ) : (
                      <p className="text-center">
                        <span className="text-center">
                          <b>₹ 0</b>
                        </span>
                      </p>
                    )}
                  </div>
                </div>

                <div className="col-md-12  d-flex">
                  <div
                    className="col-md-8"
                    style={{ marginLeft: "-1rem", fontFamily: "Raleway" }}
                  >
                    <ul className="legend primary-text">
                      <li>
                        <span
                          style={{ fontFamily: "Raleway" }}
                          className="discounted"
                        ></span>
                        100% Discount on 12th installment
                        <br />
                        <small style={{ fontFamily: "Raleway" }}>
                          (100% of 1 month installment value)
                        </small>
                      </li>
                    </ul>
                  </div>
                  {/* <div className="col-md-4">
                    {amount > 0 ? (
                      <p className="text-center">
                        <span
                          style={{ fontFamily: "Raleway" }}
                          className="text-center  "
                        >
                          <b>₹ {amount}</b>
                        </span>
                      </p>
                    ) : (
                      <p className="text-center">
                        <span className="text-center">
                          <b>₹ 0</b>
                        </span>
                      </p>
                    )}
                  </div> */}
                  <div className="col-md-4">
                    {amount > 0 ? (
                      <p className="text-center ">
                        <span className="text-center primary-text">
                          <b>₹ {amount}</b>
                        </span>
                      </p>
                    ) : (
                      <p className="text-center primary-text">
                        <span className="text-center">
                          <b>₹ 0</b>
                        </span>
                      </p>
                    )}
                  </div>
                </div>

                <div
                  className="col-md-12 m-2 d-flex"
                  style={{ borderTop: "1px solid grey" }}
                >
                  <div className="col-md-8 " style={{ marginLeft: "-1rem" }}>
                    <p
                      className="mx-4 primary-text"
                      style={{ fontFamily: "Raleway" }}
                    >
                      Buy any jwellery worth:
                      <br />
                      <small className="primary-text">(after 12 months)</small>
                    </p>
                  </div>
                  <div className="col-md-4">
                    {amount > 0 ? (
                      <p className="text-center primary-text">
                        <span className="text-center">
                          <b>₹ {amount * 12}</b>
                        </span>
                      </p>
                    ) : (
                      <p className="text-center">
                        <span className="text-center">
                          <b>₹ 0</b>
                        </span>
                      </p>
                    )}
                  </div>
                </div>

                {/* <div className="col-md-12 m-2 d-flex justify-content-center">
                  <div className="d-flex " style={{ flexDirection: "column" }}>
                    <p className="m-0">
                      <b>
                        1<sup>st</sup>
                      </b>
                    </p>
                    <div className="form-check checkbox-lg">
                      <input
                        type="checkbox"
                        checked
                        className="form-check-input"
                        disabled
                        size={"1.5rem"}
                        name=""
                        id="1st"
                      />
                    </div>
                  </div>

                  <div className="d-flex " style={{ flexDirection: "column" }}>
                    <p className="m-0">
                      <b>
                        2<sup>nd</sup>
                      </b>
                    </p>
                    <div className="form-check checkbox-lg">
                      <input
                        type="checkbox"
                        checked
                        className="form-check-input"
                        disabled
                        name=""
                        id="2nd"
                      />
                    </div>
                  </div>

                  <div className="d-flex " style={{ flexDirection: "column" }}>
                    <p className="m-0">
                      <b>
                        3<sup>rd</sup>
                      </b>
                    </p>
                    <div className="form-check checkbox-lg">
                      <input
                        type="checkbox"
                        checked
                        className="form-check-input"
                        disabled
                        name=""
                        id="3rd"
                      />
                    </div>
                  </div>

                  
                  {Array.from(Array(8).keys()).map((i) => (
                    <div
                      className="d-flex"
                      key={i}
                      style={{ flexDirection: "column" }}
                    >
                      <p className="m-0">
                        <b>
                          {i + 4}
                          <sup>th</sup>
                        </b>
                      </p>
                      <div className="form-check checkbox-lg">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          checked
                          disabled
                          name=""
                          id="4th"
                        />
                      </div>
                    </div>
                  ))}
                  <div
                    className="d-flex  primary-text"
                    style={{ flexDirection: "column" }}
                  >
                    <p className="m-0">
                      <b>
                        12<sup>th</sup>
                      </b>
                    </p>
                    <div className="form-check checkbox-lg">
                      <label className="form-check-label" htmlFor="12th">
                        <input
                          type="checkbox"
                          checked
                          className="form-check-input"
                          disabled
                          name=""
                          id="12th"
                        />
                        JMJ
                      </label>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*******Frequently asked Questions */}
      <img
        src={divider}
        width="100%"
        alt="divider "
        style={{ marginTop: "-1.6rem" }}
      ></img>

      <FrequentlyAsked />
    </div>
  );
};

export default SavingsScheme;
