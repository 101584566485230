import React, { useCallback, useRef, useState } from "react";
import { Link, NavLink, useParams } from "react-router-dom";
import { ReactComponent as CircleHeart } from "../AlIimage/circle-heart.svg";
import { ReactComponent as CircleHeartRed } from "../AlIimage/circle-heart-red.svg";
import necklace1 from "../AlIimage/necklace1.png";
import GetExpertOpinion from "./GetExpertOpinion";
import PopularProducts from "./PopularProducts";
import CustomerSayPage from "../Home/CustomerSayPage";
import axios from "axios";
import FeaturedProducts from "../Home/FeaturedProducts";
import Accordion from "react-bootstrap/Accordion";
import Exchange_icon from "../AlIimage/Exchange_icon.png";
import genuine_icon from "../AlIimage/genuine_icon.png";
import money_back_icon from "../AlIimage/money_back_icon.png";

import Modal from "react-bootstrap/Modal";
import BookAppointment from "../BookAppointment/BookAppointment";
import ProductCarousel from "../../Utils/ProductCarousel";

const SingleItem = () => {
  const [isLiked, setIsLiked] = React.useState(false);

  const [product, setProduct] = React.useState([]);

  const [loading, setLoading] = React.useState(false);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  //get id from url useParams
  const { id } = useParams();

  const getSingleProduct = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`/api/product/getOne/${id}`);
      setProduct(response.data.product);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  React.useEffect(() => {
    getSingleProduct();
  }, []);

  const [images, setImages] = React.useState([]);

  React.useEffect(() => {
    if (product.image) {
      product.image.map((image) => {
        // unique images only in array
        if (!images.includes(image.url)) {
          setImages((images) => [...images, image.url]);
        }
      });
    }
  }, [product]);

  return (
    <div>
      <div className="container2 product-header p-4">
        <div className="row">
          <div className="col-sm-6 row justify-content-center">
            <div
              className=""
              style={{
                width: "440px",
                position: "relative",
              }}
            >
              <div className="card-body">
                {loading || !product.image ? (
                  <div
                    className="spinner-border text-primary"
                    role="status"
                    style={{ position: "absolute", top: "50%", left: "50%" }}
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                ) : (
                  <ProductCarousel images={images} />
                )}
              </div>
            </div>
            <div className="col-sm-12 mt-3">
              <div
                className="row"
                style={{
                  justifyContent: "center",
                }}
              >
                <div className="col-sm-5">
                  <button
                    className="btn product-button btn-lg text-white mt-1"
                    style={{
                      top: "0",
                      right: "0",
                      zIndex: "1",
                      borderRadius: "5%",
                    }}
                  >
                    <i className="fas fa-heart "></i> &nbsp; Add to wishlist
                  </button>
                </div>
                <div className="col-sm-5">
                  <button
                    className="btn product-button btn-lg text-white mt-1"
                    onClick={handleShow}
                    style={{
                      top: "0",
                      right: "0",
                      zIndex: "1",
                      borderRadius: "4%",
                    }}
                  >
                    <i className="fas fa-video "></i> &nbsp; Video call
                  </button>
                  <Modal
                    show={show}
                    onHide={handleClose}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    closeButton
                    style={{
                      backgroundColor: "rgba(0,0,0,0.5)",
                    }}
                  >
                    <Modal.Header
                      closeButton
                      style={{ backgroundColor: "#4f1e1a" }}
                    />
                    <Modal.Body
                      closeButton
                      style={{ backgroundColor: "#fff", padding: "0" }}
                    >
                      <BookAppointment />
                    </Modal.Body>
                  </Modal>
                </div>
              </div>
            </div>
          </div>

          <div className="col-sm-6 " style={{ paddingLeft: "2rem" }}>
            <div>
              <h2
                className="product-title primary-text"
                style={{
                  fontFamily: "hahmelt",
                  fontWeight: "bold",
                }}
              >
                {product.name}
              </h2>
              <p>{product.description && product.description}</p>
            </div>
            <div>
              <Accordion defaultActiveKey={["0"]} alwaysOpen>
                <Accordion.Item eventKey="0" style={{ background: "#dfd8d2" }}>
                  <Accordion.Header>Product Information</Accordion.Header>
                  <Accordion.Body style={{ background: "#dfd8d2" }}>
                    <table
                      style={{ borderCollapse: "collapse", width: "100%" }}
                    >
                      <tbody>
                        <tr>
                          <th
                            style={{
                              textAlign: "left",
                              padding: "1rem",
                            }}
                          >
                            Product Code
                          </th>
                          <td
                            style={{
                              textAlign: "left",
                            }}
                          >
                            {product.productCode}
                          </td>
                        </tr>
                        <tr>
                          <th
                            style={{
                              textAlign: "left",
                              padding: "1rem",
                            }}
                          >
                            Purity
                          </th>
                          <td
                            style={{
                              textAlign: "left",
                            }}
                          >
                            {product.purity}
                          </td>
                        </tr>
                        <tr>
                          <th
                            style={{
                              textAlign: "left",
                              padding: "1rem",
                            }}
                          >
                            Color
                          </th>
                          <td
                            style={{
                              textAlign: "left",
                            }}
                          >
                            {product.color}
                          </td>
                        </tr>
                        <tr>
                          <th
                            style={{
                              textAlign: "left",
                              padding: "1rem",
                            }}
                          >
                            Gross Weight
                          </th>
                          <td
                            style={{
                              textAlign: "left",
                            }}
                          >
                            {product.grossWeight}
                          </td>
                        </tr>
                        <tr>
                          <th
                            style={{
                              textAlign: "left",
                              padding: "1rem",
                            }}
                          >
                            Net Weight
                          </th>
                          <td
                            style={{
                              textAlign: "left",
                            }}
                          >
                            {product.netWeight}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1" style={{ background: "#dfd8d2" }}>
                  <Accordion.Header>Stone Information</Accordion.Header>
                  <Accordion.Body style={{ background: "#dfd8d2" }}>
                    <table
                      style={{ borderCollapse: "collapse", width: "100%" }}
                    >
                      <tbody>
                        <tr>
                          <th
                            style={{
                              textAlign: "left",
                              padding: "1rem",
                            }}
                          >
                            Type
                          </th>
                          <td
                            style={{
                              textAlign: "left",
                            }}
                          >
                            {product.productCollection &&
                              product.productCollection}
                          </td>
                        </tr>

                        <tr>
                          <th
                            style={{
                              textAlign: "left",
                              padding: "1rem",
                            }}
                          >
                            Clarity
                          </th>
                          <td
                            style={{
                              textAlign: "left",
                            }}
                          >
                            {product.clarity && product.clarity}
                          </td>
                        </tr>

                        <tr>
                          <th
                            style={{
                              textAlign: "left",
                              padding: "1rem",
                            }}
                          >
                            No of Diamonds
                          </th>
                          <td
                            style={{
                              textAlign: "left",
                            }}
                          >
                            {product.NoOfDiamonds && product.NoOfDiamonds}
                          </td>
                        </tr>

                        <tr>
                          <th
                            style={{
                              textAlign: "left",
                              padding: "1rem",
                            }}
                          >
                            Diamond Carats
                          </th>
                          <td
                            style={{
                              textAlign: "left",
                            }}
                          >
                            {product.carats && product.carats}
                          </td>
                        </tr>

                        <tr>
                          <th
                            style={{
                              textAlign: "left",
                              padding: "1rem",
                            }}
                          >
                            Price
                          </th>
                          <td
                            style={{
                              textAlign: "left",
                            }}
                          >
                            {product.price && "Rs " + product.price}
                          </td>
                        </tr>

                        <tr>
                          <th
                            style={{
                              textAlign: "left",
                              padding: "1rem",
                            }}
                          >
                            Setting Type
                          </th>
                          <td
                            style={{
                              textAlign: "left",
                            }}
                          >
                            {product.settingType && product.settingType}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2" style={{ background: "#dfd8d2" }}>
                  <Accordion.Header>Other Information</Accordion.Header>
                  <Accordion.Body style={{ background: "#dfd8d2" }}>
                    <table
                      style={{
                        borderCollapse: "collapse",
                        width: "100%",
                      }}
                    >
                      <tbody>
                        <tr>
                          <th
                            style={{
                              textAlign: "left",
                              padding: "1rem",
                            }}
                          >
                            Other Information
                          </th>
                          <td
                            style={{
                              textAlign: "left",
                            }}
                          >
                            {product.otherInfo}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
            <div className="grid d-flex mt-4 justify-content-center text-center">
              <div className="m-2">
                <img src={money_back_icon} alt="money back" />
                <p>
                  <b>Money Back Guarantee</b>
                </p>
              </div>
              <div className="m-2">
                <img src={Exchange_icon} alt="exchange" />
                <p>
                  <b>Exchange & Buy-Back</b>
                </p>
              </div>
              <div className="m-2">
                <img src={genuine_icon} alt="genuine" />
                <p>
                  <b>Genuine & Certified</b>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <div className="row"></div>
          </div>
          <div className="col-md-8">
            <div className="row">
              <GetExpertOpinion
                name={product.name}
                code={product.productCode}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        {/*<PopularProducts />*/}
        <FeaturedProducts />
      </div>

      <div className="container">
        <CustomerSayPage />
      </div>

      <div className="">
        <div className="row pt-2 pb-2" style={{ background: "white" }}>
          <div className="col-md-12">
            <h3 className="text-center">
              Any Questions? Please feel free to reach us at:{" "}
              <span className="primary-text">040-66554433</span>
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleItem;
