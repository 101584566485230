import React, { useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const ProductCarousel = ({ images }) => {
  const [selectedImage, setSelectedImage] = useState(0);

  const handleThumbnailClick = (index) => {
    setSelectedImage(index);
  };

  return (
    <div>
      <Carousel
        showThumbs={false}
        selectedItem={selectedImage}
        onChange={setSelectedImage}
      >
        {images.map((image, index) => (
          <div key={index} style={{ border: "5px solid #4f1e1a" }}>
            <img src={image} alt={`Product ${index + 1}`} />
          </div>
        ))}
      </Carousel>
      <div
        style={{ marginTop: "20px", display: "flex", justifyContent: "center" }}
      >
        {images.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`Product ${index + 1}`}
            style={{
              width: "60px",
              height: "60px",
              marginRight: "10px",
              border: index === selectedImage ? "2px solid #333" : "none",
              cursor: "pointer",
            }}
            onClick={() => handleThumbnailClick(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default ProductCarousel;
