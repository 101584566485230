import "./App.css";
import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import Header from "./Components/Layouts/Header.js";
import Footer from "./Components/Layouts/Footer";
import Home from "./Components/Home.js";

import "./scss/style.scss";
import CustomerSupport from "./Components/Chatbot/CustomerSupport";
import AboutUs from "./Components/AboutUs/AboutUs";

import SingleItem from "./Components/ProductCatalogue/SingleItem";
import TermsOfUse from "./Components/Policies/TermsOfUse";
import ShippingPolicy from "./Components/Policies/ShippingPolicy";
import ReturnPolicy from "./Components/Policies/ReturnPolicy";
import PrivacyPolicy from "./Components/Policies/PrivacyPolicy";
import EasyReturn from "./Components/Policies/EasyReturn";
import CancellationPolicy from "./Components/Policies/CancellationPolicy";
import RefundPolicy from "./Components/Policies/RefundPolicy";
import ProductsAll from "./Components/ProductCatalogue/ProductsAll";
import BookAppointment from "./Components/Appointment/BookAppointment";
import ProductCategories from "./Components/ProductCatalogue/ProductCategories/ProductCategories";
import ProductCollection from "./Components/ProductCatalogue/ProductCollection/ProductCollection";
import ScrollToTop from "./Utils/ScrollToTop";
import ContactUs from "./Components/ContactUs/ContactUs";
import VideoCall from "./Components/VideoCall/VideoCall";
import SavingsScheme from "./Components/SavingsScheme/SavingsScheme";
import CampignBanner from "./Components/CampaignBanner.js";
import SignatureLookPages from "./Components/signatureLookPages.js";

function App() {
  return (
    <>
      <Router>
        {/* <Navbar /> */}
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />

          <Route path="/about" element={<AboutUs />} />

          <Route
            path="collection/:collection"
            element={<ProductCollection />}
          />
          <Route
            path="signature-looks/:name"
            element={<SignatureLookPages />}
          />

          <Route path="/category/:category" element={<ProductCategories />} />

          <Route path="product/:id" element={<SingleItem />} />

          <Route path="products/all" element={<ProductsAll />} />

          <Route path="/bookappointment" element={<BookAppointment />} />

          <Route path="/contactus" element={<ContactUs />} />

          <Route path="/videocall" element={<VideoCall />} />

          <Route path="savings-scheme" element={<SavingsScheme />} />

          <Route path="/termsofuse" element={<TermsOfUse />} />
          <Route path="/shippingpolicy" element={<ShippingPolicy />} />
          <Route path="/returnpolicy" element={<ReturnPolicy />} />
          <Route path="/refundpolicy" element={<RefundPolicy />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="/easyreturnandexchange" element={<EasyReturn />} />
          <Route path="/cancellationpolicy" element={<CancellationPolicy />} />
          <Route path="/signature" element={<CampignBanner />} />
          {/* <Route path='/login' element={<Login />} /> */}
        </Routes>
        <CustomerSupport />
        <Footer />
        {/* <ScrollToTop /> */}
      </Router>
    </>
  );
}

export default App;
