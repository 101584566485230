import React from "react";
import FollowUsPage from "../Home/FollowUsPage";
import Ourphiloshophy from "./Ourphiloshophy";
import LastYears from "./LastYears";

const AboutUs = () => {
  return (
    <div className="text-center">
      <div>
        <Ourphiloshophy />
      </div>
      <div>
        <LastYears />
      </div>
      <FollowUsPage />
    </div>
  );
};

export default AboutUs;
