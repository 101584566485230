import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// import necklace1 from "../AlIimage/necklace1.png";
// import necklace2 from "../AlIimage/necklace2.png";
// import necklace3 from "../AlIimage/necklace3.png";
// import necklace4 from "../AlIimage/necklace4.png";

import { ReactComponent as CircleHeartRed } from "../AlIimage/circle-heart-red.svg";
import axios from "axios";
import { Button } from "react-bootstrap";
const styles = {
  active: {
    // background: "#285979",
    background: "#2b5875",
    border: "2px solid white ",
    color: "#fff",
    width: "100%",
    // border: "none",
    // padding: "0.8rem",
    cursor: "pointer",
    borderRadius: "10px",
  },
  inactive: {
    background: "#336699",
    border: "2px solid white ",
    borderRadius: "10px",
    width: "100%",
  },
};
const CatalogueProducts = ({ category, productCollection, ...props }) => {
  // const [isLiked, setIsLiked] = React.useState(false);
  const number = "14155238886";
  const [products, setProducts] = React.useState([]);

  const [loading, setLoading] = React.useState(false);

  const getAllProducts = async () => {
    setLoading(true);
    try {
      const response = await axios.get("/api/product/getAll");

      // filter products based on category and productCollection
      const filteredProducts = response.data.product.filter(
        (product) =>
          product.category === category ||
          product.productCollection === productCollection
      );

      setProducts(filteredProducts);

      // setProducts(response.data.product);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    getAllProducts();
  }, []);

  const [existingButtons, setExistingButtons] = useState(["All"]);

  useEffect(() => {
    // Fetch existing buttons based on product categories
    const categories = products.map((product) => product.category);
    setExistingButtons(["All", ...new Set(categories)]);
  }, [products]);

  const buttonExists = (name) => {
    return existingButtons.includes(name);
  };
  const [activeButton, setActiveButton] = useState("All");
  const [filterProducts, setFilterProducts] = useState(products);
  const filterProduct = (name) => {
    const oldProduct = products;
    setActiveButton(name);

    if (name === "All") {
      setFilterProducts(oldProduct);
    } else {
      const filterData = oldProduct.filter(
        (product) => product.category === name
      );

      setFilterProducts(filterData);
    }
  };

  return (
    <div style={{ width: "100vw", overflowX: "hidden" }}>
      <div
        className="container"
        // style={{ margin: "auto", justifyContent: "center" }}
      >
        {!loading && products.length === 0 && (
          <div className="d-flex justify-content-center">
            <h2 style={{ color: "#285979", fontFamily: "Raleway" }}>
              No Products Found
            </h2>
          </div>
        )}
        {loading ? (
          <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          <div
            className="row justify-content-center"
            style={{
              margin: "auto",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {products.map((product) => (
              <div className="col-lg-3 sm-4 mt-2 " key={product.id}>
                <div className="card" style={{}}>
                  {/* <div className="card-header">
                      <strong style={{ fontFamily: "Hahmlet" }}>
                        {isLiked ? (
                          <CircleHeartRed
                            width="32"
                            height="32"
                            className="card-icon"
                          />
                        ) : (
                          <CircleHeart
                            width="32"
                            height="32"
                            className="card-icon"
                          />
                        )}
                      </strong>
                    </div> */}
                  <img
                    src={product?.image[0]?.url}
                    width={350}
                    height={450}
                    className="card-img-top"
                    alt="..."
                  />

                  <div className="card-footer">
                    <strong style={{ fontFamily: "Raleway", color: "#285979" }}>
                      {product?.name}
                    </strong>
                    <p className="card-text">{product?.productCode}</p>
                    <strong
                      style={{ fontFamily: "Raleway", color: "#285979" }}
                    ></strong>
                  </div>
                  <NavLink
                    onClick={() => {
                      window.open(
                        `https://wa.me/${number}?text=I'm%20interested%20in%20"${product.name}"%20"${product.productCode}"%0A%0Acontact%20me%20on%20${number}`
                      );
                    }}
                    className="btn text-white product-button"
                    width="100%"
                  >
                    Know More
                  </NavLink>
                </div>
              </div>
            ))}
            {/* {products &&
              filterProducts.length === 0 &&
              products.map((product) => (
                <div className="col-lg-3 sm-4 mt-2 " key={product.id}>
                  <div className="card" style={{}}>
                   
                    <img
                      src={product.image[0].url}
                      width={315}
                      height={290}
                      className="card-img-top"
                      alt="..."
                    />
                    <div className="card-body"></div>
                    <div className="card-footer">
                      <strong style={{ fontFamily: "Hahmlet" }}>
                        {product.name}
                      </strong>
                      <p className="card-text">{product?.productCode}</p>
                      <strong style={{ fontFamily: "Hahmlet" }}></strong>
                    </div>
                    
                    <NavLink
                      onClick={() => {
                        window.open(
                          `https://wa.me/${number}?text=I'm%20interested%20in%20"${product.name}"%20"${product.productCode}"%0A%0Acontact%20me%20on%20${number}`
                        );
                      }}
                      className="btn text-white product-button"
                      width="100%"
                    >
                      Know More
                    </NavLink>
                  </div>
                </div>
              ))} */}
          </div>
        )}
      </div>
    </div>
  );
};

export default CatalogueProducts;
