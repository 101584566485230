import React from "react";
import Policies from "./Policies";

const ReturnPolicy = () => {
  const title = "Return policy";
  const content = (
    <>
      <p>
        Jatin Mor is committed to ensuring full customer satisfaction with
        respect to the products available on our website, security & customer
        assistance. However, if you are not happy with the product, you can
        choose to return the order.
      </p>
      <b> NOTE:</b>
      <p>
        {" "}
        In case of international orders, we do not currently accept any returns
        or exchange of our products, except for those cases where a defective
        product has been delivered. You shall return the defective product
        unused and in the same condition as you received it, in its original
        packaging, along with the invoice for a refund. You are required to
        report the defect within 7 days of receipt of the product either by
        calling us on 040-23405786 or writing to us on info@jatinmorjewels.com.
        For the refund on the returned defective product please check the
        section on Refund below.
      </p>
      <b> 7- days Return Policy for Domestic orders only:</b>
      <p>
        In the unlikely event that the order delivered is in damaged condition,
        or otherwise, you can return the product unused and in the same
        condition as you received it, in its original packaging, along with the
        invoice and get the refund. Our courier partner will collect the order
        from the address provided by you and will get it back to us. Return
        Charges:Domestic Order: Our courier partner will collect the order from
        provided address (within India). Customer need not pay any shipping
        charges to return the product.
      </p>{" "}
      <b>How to Initiate the Refund Process?</b>{" "}
      <ol>
        <li>
          Customers should contact Jatin Mor customer support on 040-23405786 or
          write to info@jatinmorjewels.com within 7 days from the receipt of
          product.
        </li>{" "}
        <li>
          {" "}
          Customers should not return the product before receiving a
          confirmation mail from us about the same.
        </li>{" "}
        <li>
          {" "}
          All the products must be returned in their original condition,
          unaltered and unused.
        </li>{" "}
        <li>
          {" "}
          Provide the invoice/guarantee card into the package for return
          process. Without the above, returns will not be processed.
        </li>{" "}
        <li>
          {" "}
          The customer has to return the jewellery product in its original
          package. Without compliance of the above, returns will not be accepted
          by our shipping agent.
        </li>
      </ol>{" "}
      <p>
        <b>NOTE:</b> We shall not entertain any request after 7 days from the
        date of receipt of the product.
      </p>
      <b>Refund:</b>
      <p>
        {" "}
        We shall process the refund once we receive the product. The product
        must be in unused condition, in its original packaging along with its
        original tags and invoice, failing which refund may not be possible.
        Once the refund process has been initiated, the amount will be directly
        refunded to your account via the same mode through which the transaction
        was made or through cheque (amount would be refunded through cheque,
        only in cases where the debit/credit card used by the customer while
        placing the order is not in use). In case the customer pays online then
        the amount will be refunded within 7-15 working days from the receipt of
        returned product(s) at our warehouse.
      </p>
      <b>Domestic Order:</b>
      <p>
        {" "}
        In case of domestic order (where the product is shipped within India)
        100% refund will be made.In case the customer pays online then the
        amount will be refunded within 7-15 working days from the receipt of the
        returned product(s) at our warehouse.In case of COD (Cash on delivery
        i.e. when the customer pays in cash once the product is received), the
        amount can be refunded either through cheque or online transfer as NEFT
        (National Electronic Fund Transfer). The mode of refund depends on the
        customers choice:1. If the customer provides a cancelled cheque with the
        returned product then the refund can be made within 7-15 working days &
        amount will be refunded directly into the customer's account through
        online transfer as NEFT.2. If the customer doesn't provide any cancelled
        cheque then the amount will be refunded through cheque that will be sent
        by courier at the address provided by the customer at the time of
        registering/placing the order. It may take up to 30 working days.
      </p>{" "}
      <b>International Order:</b>
      <p>
        {" "}
        For international orders, currently, we do not provide for refund
        policy. Although, Jatin Mor will refund the amount to the customer only
        under following two circumstances and at the sole discretion of Jatin
        Mor:
        <ol>
          <li>
            {" "}
            We will accept the return of only a defective product and then
            subsequently provide for the refund. However, the refund process
            will be initiated only post inspection and after we get a go-ahead
            from our QA (quality assurance) team. While refunding for the
            defective product, we will not charge you for shipment (to and fro).
          </li>{" "}
          <li>
            {" "}
            We will refund under those circumstances where our logistic partner
            has been unable to deliver the product, even after repeated efforts
            due to the customers unavailability. Although while making the
            refund, we will deduct from the amount paid all the costs incurred
            by us like shipment expenses both to and fro, customs paid and any
            other charges.
          </li>
        </ol>
      </p>
    </>
  );
  return (
    <div>
      <Policies title={title} content={content} />
    </div>
  );
};

export default ReturnPolicy;
