import React, { useEffect } from "react";
import { Container, Row, Col, Carousel, Button } from "react-bootstrap"; // Import Button from react-bootstrap
import axios from "axios";
import { Text } from "@fortawesome/fontawesome-svg-core";
import { useNavigate } from "react-router-dom";

const CampaignBanner = () => {
  const [campaignBannersData, setCampaignBannersData] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const navigate = useNavigate();
  const getCampaignBannersData = async () => {
    axios
      .get(`/api/campaignBanner/getAll/`)
      .then((res) => {
        console.log(res);
        setCampaignBannersData(res.data?.campaignBanner);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getCampaignBannersData();
  }, []);

  return (
    <div
      style={{
        color: "#FFFFFF",
        margin: "0rem 0rem",
        background: "#e1f3ff",
        paddingBottom: "1rem",
      }}
    >
      <div style={{ paddingTop: "0.5rem" }}>
        <span className="title">Signature Looks</span>
      </div>

      {loading && (
        <div className="d-flex justify-content-center  mt-5">
          <div className="spinner-border text-dark" role="status">
            <span className="sr-only"></span>
          </div>
        </div>
      )}
      <Carousel
        vertical="horizontal"
        fade
        style={{ marginBottom: "1rem" }}
        interval={3000}
        autoPlay
        infiniteLoop
      >
        {!loading &&
          campaignBannersData?.map((campaignBanner) => (
            <Carousel.Item
              style={{ position: "relative" }} // Add position relative
              key={campaignBanner._id}
            >
              <img
                className="carousel-campaign"
                src={campaignBanner?.image[0].url}
                alt="slide"
                style={{
                  width: "100%",
                  alignContent: "center",
                  display: "block",
                  padding: "0rem 0.5rem 0rem 0.5rem ",
                  // marginBottom: "1rem",
                  maxHeight: "750px",
                }}
              />
              {/* Button placed at the center */}
              <button
                // variant="primary"
                size="lg"
                style={{
                  position: "absolute",
                  top: "58%",
                  left: "75%",
                  // fontSize: "1.5rem",
                  // padding: "0rem 2rem",
                  transform: "translate(-50%, -50%)",
                }}
                className="custom-button5"
                onClick={() =>
                  navigate(`/signature-looks/${campaignBanner.name}`)
                }
              >
                EXPLORE
              </button>
            </Carousel.Item>
          ))}
      </Carousel>
    </div>
  );
};

export default CampaignBanner;
