import React from "react";
import pattern4 from "../AlIimage/Pattern4.svg";

const Ourphiloshophy = () => {
  return (
    <div
      className="container-fluid"
      style={{
        backgroundImage: `url(${pattern4})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "auto",
        backgroundPositionX: "left top",
        width: "100%",
      }}
    >
      <div className="container" style={{ marginBottom: "4rem" }}>
        <div className="row">
          <div className="col-sm " style={{ marginTop: "2rem" }}>
            <p
              style={{
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "16px",
                lineHeight: "24px",
                textAlign: "center",
                color: "#000000",
              }}
            >
              About Us
            </p>

            <p
              className="container-title text-center"
              style={{ marginBottom: "1rem", marginTop: "2rem" }}
            >
              Our Philosophy
            </p>

            <div
              className="container"
              style={{
                alignItems: "center",
                justifyContent: "center",
                padding: "5%",
              }}
            >
              <p className="small" style={{ textAlign: "left" }}>
                The history of jewellery making at Mor dates back to the
                eighteenth century when our founder Shri. Anand Mor met with the
                idea of a wholesale jewellery business.
              </p>

              <p className="small" style={{ textAlign: "left" }}>
                Risen in the land of beauty and affluence, the Zenab of Nizams,
                Jatin Mor Jewels inculcates in itself, the rich history and
                cultural influence of Hyderabad, which was once deemed as the
                largest princely state. Our designs are intricate and depict in
                every piece the epitome of craftsmanship, which received high
                accolades to our founders. Appreciation and inspiration for fine
                arts and designs led our founders to step into the world of the
                retail jewellery business.
              </p>

              <p className="small" style={{ textAlign: "left" }}>
                {" "}
                The grand legacy is now being carried to a new high by Shri.
                Jatin Mor, standing tall and majestic as Jatin Mor Jewels,
                distinguished to be one of the best designer jewellery outlets
                in India.
              </p>

              <p className="small" style={{ textAlign: "left" }}>
                {" "}
                Our designs are a delightful amalgamation of modern age
                designing technology captivating with it the allure of the
                opulent Indian heritage of intricate designs. Our designers and
                craftsmen are chaperoned to keep up with the ebb and flow of the
                ever-changing fashion trends whilst preserving the legacy
                inherited from ancient India.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ourphiloshophy;
