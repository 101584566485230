import React from "react";
import img1 from "../AlIimage/signatureLooksImgs/wetransfer_kaaynat_2024-02-02_0700/9X4A5219.jpg";
import img2 from "../AlIimage/signatureLooksImgs/wetransfer_kaaynat_2024-02-02_0700/9X4A5222.jpg";
import img3 from "../AlIimage/signatureLooksImgs/wetransfer_kaaynat_2024-02-02_0700/9X4A5243 copy (1).jpg";
import img4 from "../AlIimage/signatureLooksImgs/wetransfer_kaaynat_2024-02-02_0700/9X4A5283.jpg";
import img5 from "../AlIimage/signatureLooksImgs/wetransfer_kaaynat_2024-02-02_0700/9X4A5430 1.jpg";
import img6 from "../AlIimage/signatureLooksImgs/wetransfer_kaaynat_2024-02-02_0700/9X4A5305.jpg";
import { Container } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
const imagees = [img1, img2, img3, img4, img5, img6];
const Kaaynat = ({ btnClick }) => {
  const isSmallScreen = useMediaQuery({ maxWidth: 600 });
  return (
    <div style={{ marginTop: "0rem" }}>
      <Container>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <p className="title">Kaaynat</p>
          {/* <div style={{ marginTop: "-1rem" }}>
            <button
              onClick={() => {
                window.open(btnClick);
              }}
              
              className="custom-button1"
            >
              {`Click here to shop this look`.toUpperCase()}
            </button>
            
          </div> */}
        </div>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            gap: "1rem",
            margin: "0rem 1rem",
          }}
        >
          {imagees.map((item, i) => (
            <div
              style={{
                position: "relative",
                width: "30%",
                marginBottom: "1rem",
              }}
            >
              <img src={item} alt="nazakath" style={{ width: "100%" }} />
              <i
                onClick={() => {
                  window.open(btnClick);
                }}
                class="far fa-heart"
                style={{
                  position: "absolute",
                  top: "5px",
                  cursor: "pointer",
                  right: "5px",
                  fontSize: isSmallScreen ? "20px" : "34px",
                  color: "#285979",
                }}
              ></i>
            </div>
          ))}
        </div>
      </Container>
    </div>
  );
};

export default Kaaynat;
