import React from "react";

import aboutuslegacy from "../AlIimage/aboutuslegacy.svg";

const LastYears = () => {
  return (
    <div>
      {" "}
      <div
        className="mt-4"
        style={{
          width: "100%",
          height: "80vh",
          marginBottom: "15%",
        }}
      >
        <div className="container my-4">
          <div className="grid-about m-0 p-0" style={{}}>
            <div className="item" style={{ width: "100%" }}>
              <a href="#">
                <img className="img-fluid" src={aboutuslegacy} alt="..." />
              </a>
            </div>
            <div className="item">
              <a>
                <div className="text-wrapper" style={{}}>
                  <p
                    style={{
                      fontSize: "40px",
                      marginBottom: "1rem",
                      marginTop: "0.5rem",
                      textAlign: "left",
                    }}
                  >
                    What We Have Been Doing For The Last 132 Years
                  </p>
                  <p
                    className="small"
                    style={{
                      verticalAlign: "middle",
                      textAlign: "left",
                    }}
                  >
                    Maintaining the quality of products to meet our clients
                    needs
                  </p>
                  <p
                    className="small"
                    style={{
                      verticalAlign: "middle",
                      textAlign: "left",
                    }}
                  >
                    Achieving a worthy reputation by delivering genuine
                    jewellery to our customers
                  </p>
                  <p
                    className="small"
                    style={{
                      verticalAlign: "middle",
                      textAlign: "left",
                    }}
                  >
                    Making jewellery that lasts a lifetime
                  </p>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LastYears;
