import React, { useEffect, useState } from "react";

import trustedJwellers from "../AlIimage/trustedJwellers.png";
import certifiedDiamonds from "../AlIimage/certifiedDiamonds.png";
import freeShipping from "../AlIimage/FreeShipping.png";
import easyReturns from "../AlIimage/EasyReturns.png";
import yearsoftrust from "../AlIimage/125Years.png";
import BisCertified from "../AlIimage/BisCertified.png";
import { Helmet } from "react-helmet";
import { useMediaQuery } from "react-responsive";

const FollowUsPage = () => {
  const [showLoading, setShowLoading] = useState(true);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShowLoading(false);
    }, 6000);
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://static.elfsight.com/platform/platform.js";
    script.defer = true;
    document.head.appendChild(script);

    return () => {
      // Cleanup to remove the script when the component is unmounted
      document.head.removeChild(script);
    };
  }, []);
  const isSmallScreen = useMediaQuery({ maxWidth: 600 });
  return (
    <>
      <div
        style={{
          backgroundColor: "#E1F3FF",
          padding: "1rem 0rem ",
        }}
      >
        <div
          className="container2"
          style={{ marginTop: isSmallScreen ? "-1rem" : "1rem" }}
        >
          <div className="row" style={{ margin: "auto" }}>
            <div className="col-sm-12 col-md-4 col-lg-4 ">
              {/* <img src={followusonig} alt="" width="100%" /> */}
              <span
                className="followUs"
                // style={{
                //   fontFamily: "Felix Titling",
                //   fontSize: "50px",
                //   fontWeight: 500,
                //   lineHeight: "60px",
                //   letterSpacing: "0em",
                //   textAlign: "center",
                //   color: "#285979",

                //   marginBottom: "1rem",
                // }}
              >
                FOLLOW US ON INSTAGRAM
              </span>
              {/* <div style={{ display: "flex", alignItems: "center" }}>
                <a
                  href="https://www.instagram.com/jatinmorjewels/?hl=en"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <br />
                  <img
                    src={followus}
                    alt="Follow us"
                    style={{
                      width: "70%",
                      borderRadius: "10%",
                      cursor: "pointer",
                    }}
                  />
                  <span
                    style={{
                      fontFamily: "Felix Titling",
                      fontSize: "50px",
                      fontWeight: 350,
                      lineHeight: "60px",
                      letterSpacing: "0em",
                      textAlign: "center",
                      color: "#285979",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginBottom: "1rem",
                    }}
                  >
                    FOLLOW US ON INSTAGRAM
                  </span>
                </a>
              </div> */}
              {showLoading && (
                <div style={{ paddingLeft: "250%" }}>Loading...</div>
              )}
            </div>

            <div className="col-sm-12 col-md-8 col-lg-8 mt-1">
              <div className="row">
                {/* Add your existing code here */}
                {/* <script src="https://static.elfsight.com/platform/platform.js" data-use-service-core defer></script>
<div class="elfsight-app-c79faf1b-ec73-4398-8f5b-5f001d10a15c" data-elfsight-app-lazy></div> */}
                <Helmet>
                  {/* <script
                    src="https://static.elfsight.com/platform/platform.js"
                    defer
                  ></script> */}
                  <script
                    src="https://static.elfsight.com/platform/platform.js"
                    data-use-service-core
                    defer
                  ></script>
                </Helmet>
                {/* <div
                  className="elfsight-app-635ce5f0-b105-49bd-82ce-137162ef291f"
                  data-elfsight-app-lazy
                ></div> */}
                <div
                  class="elfsight-app-c79faf1b-ec73-4398-8f5b-5f001d10a15c"
                  data-elfsight-app-lazy
                ></div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "12%",
            margin: "2rem",
          }}
        >
          <div>
            <img width={"100%"} src={trustedJwellers} alt="" />
          </div>
          <div>
            <img width={"100%"} src={certifiedDiamonds} alt="" />
          </div>
          <div>
            <img width={"100%"} src={BisCertified} alt="" />
          </div>
          <div>
            <img width={"100%"} src={yearsoftrust} alt="" />
          </div>
          <div>
            <img width={"100%"} src={freeShipping} alt="" />
          </div>
          <div>
            <img width={"100%"} src={easyReturns} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default FollowUsPage;
