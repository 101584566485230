import axios from "axios";
import React, { useState } from "react";
import { Button } from "react-bootstrap";

export default function CelebritiesComponent({ src }) {
  const [isButton, setIsButton] = useState(false);
  const [socialMedia, setSocialMedia] = React.useState([]);

  // const [loading, setLoading] = React.useState(false);
  const [config, setConfig] = React.useState([]);

  const getConfigDetails = async () => {
    try {
      const response = await axios.get("/api/config");
      setConfig(response?.data?.result);
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    getConfigDetails();
  }, []);

  React.useEffect(() => {
    if (config.length > 0) {
      setSocialMedia(config[0]?.socialMedia);
    }
  }, [config]);
  const containerStyle = {
    position: "relative",
    overflow: "hidden",
  };
  const imageStyle = {
    width: "100%",
    height: "auto",
    display: "block",

    filter: isButton ? "blur(5px)" : "none",
  };
  const buttonContainerStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 1,
  };
  const buttonStyle = {
    padding: "8px",
    // background: "#285979",
    background: "transparent",
    border: "2px solid white ",
    color: "#fff",

    // border: "none",
    cursor: "pointer",
    borderRadius: "10px",
    fontSize: "0.9rem",
    fontWeight: "bold",
  };

  const handleImageHover = () => {
    setIsButton(true);
  };

  const handleImageLeave = () => {
    setIsButton(false);
  };

  return (
    <div
      className="image-container"
      style={containerStyle}
      onMouseEnter={handleImageHover}
      onMouseLeave={handleImageLeave}
    >
      <img className="d-block" src={src} alt={`Bride `} style={imageStyle} />
      {isButton && (
        <div style={buttonContainerStyle}>
          <button
            onClick={() => {
              window.open(
                `https://wa.me/${socialMedia[0]?.whatsappNumber}?text=Hi%20I%20am%20interested%20in%20your%20products`
              );
            }}
            // style={buttonStyle}
            className="custom-button"
          >
            {`Shop this look with us`.toUpperCase()}
          </button>
          {/* <Button variant="outline-primary" className="custom-button">
            Primary
          </Button> */}
        </div>
      )}
    </div>
  );
}
