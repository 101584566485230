import React, { useEffect, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import "./Navbar.css";
import JatinLogo from "../AlIimage/JatinLogoTrans.png";
import jmjlogo from "../AlIimage/JMJlogo.png";
import titlelogo from "../AlIimage/JMJlogo1.png";
import { useRef } from "react";
import userLogo from "../AlIimage/user.svg";
import cartLogo from "../AlIimage/shopping-bag.svg";
import { useScrollDirection } from "../../Utils/UseScrollDirection";
import axios from "axios";

function NavBar() {
  const [loadingCategories, setLoadingCategories] = useState(false);
  const [productCategoriesData, setProductCategoriesData] = useState([]);
  const [loadingCollections, setLoadingCollections] = useState(false);
  const [productCollectionsData, setProductCollectionsData] = useState([]);

  const getProductCategoriesData = async () => {
    axios
      .get(`api/productCategory/getAll`)
      .then((res) => {
        setLoadingCategories(true);
        setProductCategoriesData(res.data.productCategory);
        setLoadingCategories(false);
      })
      .catch((err) => {
        console.log(err);
        setLoadingCategories(false);
      });
  };

  const getProductCollectionsData = async () => {
    axios
      .get(`api/productCollection/getAll`)
      .then((res) => {
        setLoadingCollections(true);
        setProductCollectionsData(res.data.productCollection);
        setLoadingCollections(false);
      })
      .catch((err) => {
        console.log(err);
        setLoadingCollections(false);
      });
  };

  useEffect(() => {
    getProductCategoriesData();
    getProductCollectionsData();
  }, []);
  const [campaignBannersData, setCampaignBannersData] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const navigate = useNavigate();
  const getCampaignBannersData = async () => {
    axios
      .get(`/api/campaignBanner/getAll/`)
      .then((res) => {
        console.log(res);
        setCampaignBannersData(res.data?.campaignBanner);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getCampaignBannersData();
  }, []);

  const campaignBannerRef = useRef(null);
  const [click, setClick] = useState(false);
  const scrollDirection = useScrollDirection();

  const handleClick = () => setClick(!click);

  const scrollToCampaignBanner = () => {
    if (campaignBannerRef.current) {
      campaignBannerRef.current.scrollIntoView({ behavior: "smooth" });
    }
    setClick(false); // Close the mobile menu if it's open
  };
  console.log("scroll", scrollDirection);
  return (
    <>
      <nav
        className={`navbar sticky ${
          scrollDirection === "down" ? "-top-24" : "top-0"
        }`}
      >
        <div className="d-flex">
          <div className="">
            <NavLink exact to="/">
              {/* CodeBucks */}
              <img src={jmjlogo} alt="Jatin Mor" width={"70%"} />
              {/* <i className="fas fa-code"></i> */}
            </NavLink>
          </div>
          <div>
            <span> &nbsp;&nbsp;&nbsp;</span>
          </div>

          <div className="">
            <NavLink exact to="/" className="nav-logo">
              {/* CodeBucks */}
              <img
                src={titlelogo}
                alt="Jatin Mor"
                width={"80%"}
                style={{ marginLeft: "-2rem", marginTop: "0.3rem" }}
              />
              {/* <i className="fas fa-code"></i> */}
            </NavLink>
          </div>

          <div className="ms-3" style={{ marginTop: "0.5rem" }}>
            <ul className={click ? "nav-menu active" : "nav-menu"}>
              <li className="nav-item">
                <NavLink
                  exact
                  to="/"
                  className="nav-links"
                  onClick={handleClick}
                >
                  Home
                </NavLink>
              </li>

              <li className="nav-item1 dropdown">
                <NavLink
                  exact
                  className="nav-links dropdown-toggle"
                  // to={"/products/all"}
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  // onClick={handleClick}
                >
                  Collections{" "}
                </NavLink>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="navbarDropdownMenuLink"
                >
                  {loadingCollections ? (
                    <div className="text-center">
                      <div className="spinner-border text-light" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  ) : (
                    <>
                      {productCollectionsData.map((collection) => (
                        <li key={collection._id}>
                          <Link
                            style={{ color: "#285979", fontFamily: "Raleway" }}
                            className="dropdown-item"
                            to={`/collection/${collection.name}`}
                            onClick={handleClick}
                          >
                            {collection.name}
                          </Link>
                        </li>
                      ))}
                    </>
                  )}
                </ul>
              </li>

              {/* <li className="nav-item">
                <NavLink
                  exact
                  to="signature"
                  className="nav-links"
                  onClick={() => {
                    handleClick();
                    scrollToCampaignBanner();
                  }}
                >
                  Signature
                </NavLink>
              </li> */}
              <li className="nav-item1 dropdown">
                <NavLink
                  exact
                  className="nav-links dropdown-toggle"
                  // to={"/products/all"}
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  // onClick={handleClick}
                >
                  Signature{" "}
                </NavLink>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="navbarDropdownMenuLink"
                >
                  {loading ? (
                    <div className="text-center">
                      <div className="spinner-border text-light" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  ) : (
                    <>
                      {campaignBannersData.map((banner) => (
                        <li key={banner._id}>
                          <Link
                            style={{ color: "#285979", fontFamily: "Raleway" }}
                            className="dropdown-item"
                            onClick={handleClick}
                            to={`/signature-looks/${banner.name}`}
                          >
                            {banner.name}
                          </Link>
                        </li>
                      ))}
                    </>
                  )}
                </ul>
              </li>

              <li className="nav-item">
                <NavLink
                  exact
                  to="/savings-scheme"
                  className="nav-links"
                  onClick={handleClick}
                >
                  Savings Scheme
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink
                  exact
                  to="/videocall"
                  className="nav-links"
                  onClick={handleClick}
                >
                  Video Call
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink
                  exact
                  to="/contactus"
                  className="nav-links"
                  onClick={handleClick}
                >
                  Contact us
                </NavLink>
              </li>
            </ul>
          </div>
          {/*  icons 
              <div
              className="ms-3 justify-content-end d-flex ml-auto"
              style={{
                marginLeft: "auto",
                alignContent: "right",
                alignItems: "right",
                justifyContent: "right",
              }}
            >
              <ul className={click ? "nav-menu active ml-auto" : "nav-menu "}>
                <li className="nav-item">
                  <NavLink
                    exact
                    to="/user"
                    
                    className="nav-links"
                    onClick={handleClick}
                  >
                    <img
                      src={userLogo}
                      alt="user"
                      style={{ mixBlendMode: "darken" }}
                    />
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    exact
                    to="/cart"
                    
                    className="nav-links"
                    onClick={handleClick}
                  >
                    <img src={cartLogo} alt="cart" />
                  </NavLink>
                </li>
              </ul>
            </div>*/}
        </div>

        {/* <li className="nav-item">
                        <NavLink
                            exact
                            to="/user"
                            
                            className="nav-links"
                            onClick={handleClick}
                        >
                            User
                        </NavLink>
                    </li> */}

        <div className="nav-icon" onClick={handleClick}>
          <i className={click ? "fas fa-times" : "fas fa-bars"}> </i>
        </div>
        {/* <div className="w-25 ms-5">
                        <ul className={click ? "nav-menu active" : "nav-menu "}>
                            <li className="nav-item">
                                <NavLink
                                    exact
                                    to="/user"
                                    
                                    className="nav-links"
                                    onClick={handleClick}
                                >
                                    <img src={userLogo} alt="" />
                                </NavLink>


                               
                            </li>
                            <li className="nav-item">
                                <NavLink
                                    exact
                                    to="/cart"
                                    
                                    className="nav-links"
                                    onClick={handleClick}
                                >
                                    <img src={cartLogo} alt="" />
                                </NavLink>
                            </li>

                        </ul>
                    </div> */}
      </nav>
    </>
  );
}

export default NavBar;
