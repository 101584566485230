import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import homepageimg from "./AlIimage/homepageimg.png";

import divider from "./AlIimage/page breaker.png";

import { useRef } from "react";

// import CustomerSayPage from "./Home/CustomerSayPage";
import FollowUsPage from "./Home/FollowUsPage";

import OurCollection from "./Home/OurCollection";
import OurLegacy from "./Home/OurLegacy";
// import FeaturedProducts from "./Home/FeaturedProducts";
import Celebrities from "./Home/Celebrities";
// import styled from "styled-components";
// import HomeBanner from "./HomeBanner";
import CampaignBanner from "./CampaignBanner";
import Bride from "./Home/brides";

const Home = () => {
  const campaignBannerRef = useRef(null);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    swipeToSlide: true,
    slidesToShow: 6,
    slidesToScroll: 6,
    autoplay: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
          infinite: true,
          dots: false,
        },
      },

      {
        breakpoint: 800,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: true,
          dots: false,
        },
      },

      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },

      {
        breakpoint: 320,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 280,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  let vh = window.innerHeight * 0.01;
  // Then we set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty("--vh", `${vh}px`);

  // We listen to the resize event
  window.addEventListener("resize", () => {
    // We execute the same script as before
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  });

  return (
    <>
      <div
        className="mh-100"
        style={{ width: "100vw", overflowX: "hidden", background: "#FEFEFF" }}
      >
        <div className="p-0">
          <div
            style={{
              background: "#FFFFFF",
              //backgroundImage: `url(${HeroPattern})`,
              backgroundPositionX: "center",
              backgroundRepeat: "no-repeat",
              backgroundTop: "0rem",
            }}
          >
            {/* <div
              style={{
                marginTop: "0rem",
                width: "",
              }} */}

            <div
              style={{
                display: "flex",
                justifyContent: "flex",
                alignItems: "flex",
                verticalAlign: "center",
                objectFit: "cover",
              }}
            >
              <img src={homepageimg} alt="" style={{ width: "100%" }} />
            </div>
            {/***slick slide icons** */}
            <img
              src={divider}
              width="100%"
              alt="divider "
              style={{ marginBottom: "-1.25rem" }}
              // className="divider"
            ></img>

            {/*second  campaign banner */}
            <div ref={campaignBannerRef} id="campaignBanner">
              <CampaignBanner />
              {/*
             <Carousel
              vertical="horizontal"
              fade
              interval={3000}
              autoPlay
              infiniteLoop
            >
              <Carousel.Item>
                <img src={banner1} />
               
              </Carousel.Item>
              <Carousel.Item>
                <img src={banner2} />
              </Carousel.Item>
              <Carousel.Item>
                <img src={banner3} />
                
              </Carousel.Item>
           </Carousel>
          */}
            </div>
          </div>
          <img
            src={divider}
            width="100%"
            style={{ marginTop: "-1.7rem" }}
            alt="divider"
          />
          <div
            style={{
              background: "#E1F3FF",
            }}
          >
            <OurCollection />
          </div>
          <img
            src={divider}
            width="100%"
            style={{ marginBottom: "-1.3rem" }}
            alt="divider"
          />
          <Celebrities />
          <img
            src={divider}
            style={{ marginTop: "-1.8rem" }}
            width="100%"
            alt="divider"
          />

          <div
            style={{
              background: "#FFFFFF",
            }}
          >
            <Bride />
          </div>

          {/* 3rd */}
          {/* <div
            style={{
              background: "#E1F3FF",
            }}
          >
            <OurCollection />
          </div> */}
          <img
            src={divider}
            width="100%"
            style={{ marginTop: "-2rem" }}
            alt="divider"
          />

          {/* 4rd */}
          <OurLegacy />
          <img
            src={divider}
            width="100%"
            style={{ marginBottom: "-1.4rem" }}
            alt="divider"
          />

          {/* 5th */}

          {/* 6th div */}

          {/* 7th  */}

          {/* 8th */}

          <FollowUsPage />

          {/* last footer 
          <div className="">
            <img src={Footerpattern} alt="" width="100%" />
          </div>*/}
        </div>
      </div>
    </>
  );
};

export default Home;
