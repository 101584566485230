import React from "react";
import { useLocation } from "react-router-dom";
import Paimaaish from "./signatureLookLgPages/Paimaaish";
import Riwaaz from "./signatureLookLgPages/Riwaaz";
import Kaaynat from "./signatureLookLgPages/Kaaynat";
import Jharokha from "./signatureLookLgPages/Jharokha";
import Nazakath from "./signatureLookLgPages/Nazakath";
import axios from "axios";
// import OurCollection from "./Home/OurCollection";
// import Celebrities from "./Home/Celebrities";
// import Riwaaz from "./signatureLookLgPages/Riwaaz";
// import Nazakath from "./signatureLookLgPages/Nazakath";
// import Paimaaish from "./signatureLookLgPages/Paimaaish";
// import Kaaynat from "./signatureLookLgPages/Kaaynat";
// import Jharokha from "./signatureLookLgPages/Jharokha";

export default function SignatureLookPages() {
  const location = useLocation();
  const page = location.pathname.split("/")[2];
  const [socialMedia, setSocialMedia] = React.useState([]);

  // const [loading, setLoading] = React.useState(false);
  const [config, setConfig] = React.useState([]);

  const getConfigDetails = async () => {
    try {
      const response = await axios.get("/api/config");
      setConfig(response?.data?.result);
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    getConfigDetails();
  }, []);

  React.useEffect(() => {
    if (config.length > 0) {
      setSocialMedia(config[0]?.socialMedia);
    }
  }, [config]);
  const btnClick = `https://wa.me/${socialMedia[0]?.whatsappNumber}?text=Hi%20I%20am%20interested%20in%20your%20products`;
  return (
    <div>
      <div>
        {page === "Paimaaish" && <Paimaaish btnClick={btnClick} />}
        {page === "Jharokha" && <Jharokha btnClick={btnClick} />}
        {page === "Kaaynaat" && <Kaaynat btnClick={btnClick} />}
        {page === "Riwaaz" && <Riwaaz btnClick={btnClick} />}
        {page === "Nazakth" && <Nazakath btnClick={btnClick} />}
      </div>
    </div>
  );
}
