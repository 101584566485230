import axios from "axios";
import React, { useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";

const FrequentlyAsked = () => {
  const [loading, setLoading] = useState(true);
  const [faq, setFaq] = useState([]);

  const getFAQsData = async () => {
    axios
      .get(`/api/faq/getAll/`, {})
      .then((res) => {
        setFaq(res.data?.faq);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getFAQsData();
  }, []);

  return (
    <div className="container" style={{ padding: "0px" }}>
      <span
        className="title"
        // style={{ marginTop: "-1rem" }}
        // style={{
        //   color: "#285979",
        //   fontSize: "24px",
        //   fontFamily: "Felix Titling",
        //   fontWeight: "bold",
        // }}
      >
        Frequently Asked Questions
      </span>
      <div>
        <Accordion defaultActiveKey="0">
          {faq?.map((item, index) => {
            return (
              <div key={item._id} className="m-2">
                <Accordion.Item eventKey={index}>
                  <Accordion.Header>
                    <p
                      style={{
                        margin: 0,
                        padding: 0,
                        color: "#285979",
                        fontFamily: "Raleway",
                      }}
                    >
                      {item.question}
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p
                      style={{
                        margin: 0,
                        padding: 0,
                        color: "#285979",
                        fontFamily: "Raleway",
                      }}
                    >
                      {item.answer}
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
              </div>
            );
          })}
        </Accordion>
      </div>
    </div>
  );
};

export default FrequentlyAsked;
