import React from "react";

import CatalogueTitle from "./CatalogueTitle";

import CatalogueProducts from "./CatalogueProducts";

const Catalogue = ({
  title,
  titleText,
  titleImage,
  category,
  productCollection,
  ...props
}) => {
  return (
    <div style={{ width: "100vw", overflowX: "hidden" }}>
      <div className="container">
        <CatalogueTitle
          title={title}
          titleText={titleText}
          titleImage={titleImage}
        />
      </div>
      <div className="catalogue-products mt-4">
        <CatalogueProducts
          category={category}
          productCollection={productCollection}
        />
      </div>
    </div>
  );
};

export default Catalogue;
