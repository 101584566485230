import React from "react";

const Policies = ({ title, content }) => {
  return (
    <div className="container" style={{ marginBottom: "5rem" }}>
      <p className="container-title" style={{ marginBottom: "5rem" }}>
        {title}
      </p>
      <p className="small" style={{ textAlign: "left", padding: "0 3rem" }}>
        {content}
      </p>
    </div>
  );
};

export default Policies;
