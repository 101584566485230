import React, { useState } from "react";
import axios from "axios";

import fproduct1 from "../AlIimage/fproduct1.png";
import fproduct2 from "../AlIimage/fproduct2.png";
import fproduct3 from "../AlIimage/fproduct3.png";

const number = "14155238886"; // twilio number

// sample data
const featuredProducts = [
  {
    id: 1,
    name: "Gold Ring",
    image: fproduct1,
    weight: "6.03 g",
    price: "Rs. 20,000",
    code: "QWERT",
  },
  {
    id: 2,
    name: "Earrings",
    image: fproduct2,
    weight: "6.03 g",
    price: "Rs. 20,000",
    code: "TYSEW",
  },
  {
    id: 3,
    name: "Necklace",
    image: fproduct3,
    weight: "6.03 g",
    price: "Rs. 20,000",
    code: "NYIKS",
  },
];

const FeaturedProducts = () => {
  const [loading, setLoading] = useState(false);
  const [featuredProducts, setFeaturedProducts] = useState([]);
  const [displayedProducts, setDisplayedProducts] = useState(3);

  //get products from database
  const getFeaturedProducts = async () => {
    setLoading(true);
    try {
      const response = await axios.get("/api/product/getAll");

      // filter featured products
      const filteredProducts = response.data.product.filter(
        (product) => product.setAsFeatured === true
      );

      // sort products by updatedAt
      filteredProducts.sort((a, b) => {
        return new Date(b.updatedAt) - new Date(a.updatedAt);
      });

      setFeaturedProducts(filteredProducts);

      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  React.useEffect(() => {
    getFeaturedProducts();
  }, []);

  // handle whatsapp button click
  const handleWhatsapp = (product) => {
    // axios
    //   .post("/api/leads/getAllMessages")
    //   .then((res) => {
    //     console.log(res);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });

    window.open(
      `https://wa.me/${number}?text=I'm%20interested%20in%20"${product.name}"%20"${product.productCode}"%0A%0Acontact%20me%20on%20${number}`
    );
  };

  return (
    <div
      className="container-fluid   pb-5 p-0"
      style={{ margin: "auto", justifyContent: "center" }}
    >
      <h4
        className=" text-center container-title"
        style={{ fontStyle: "normal", fontFamily: "Hahmlet" }}
      >
        Featured Products
      </h4>

      {loading && (
        <div className="d-flex justify-content-center mt-5">
          <div className="spinner-border" role="status">
            <span className="sr-only"></span>
          </div>
        </div>
      )}

      {
        /* no products found */
        featuredProducts.length === 0 && !loading && (
          <div className="d-flex justify-content-center mt-5">
            <h5 style={{ color: "#285979", fontFamily: "Raleway" }}>
              No products found
            </h5>
          </div>
        )
      }

      <div className=" row  " style={{ margin: "auto" }}>
        {/* map cards with sample data 
         and display only 3 items and show more button */}
        {featuredProducts.slice(0, displayedProducts).map((product) => (
          <div
            className="mt-1 p-3 col-lg-4 sm-4"
            key={product._id}
            style={{ maxWidth: "380px", margin: "auto" }}
          >
            <div className="card" style={{ margin: "1px" }}>
              <img
                src={product?.image[0]?.url}
                className="card-img-top"
                alt="..."
                width="100%"
                style={{
                  float: "left",
                  width: "",
                  height: "300px",
                  objectFit: "cover",
                }}
              />
              <div className="card-footer">
                <p className="card-title">
                  {product.name}
                  <span style={{ textAlign: "right", float: "right" }}>
                    {product.grossWeight}
                  </span>
                </p>
                <strong style={{ fontFamily: "Hahmlet", padding: "0.25rem" }}>
                  {" "}
                  {product.productCode}
                </strong>
              </div>
            </div>

            <div className="mt-1">
              <button
                className="text-white product-button"
                style={{}}
                onClick={() => handleWhatsapp(product)}
              >
                Know more
              </button>
            </div>
          </div>
        ))}
      </div>

      {/* Show More button */}
      {featuredProducts.length > displayedProducts && (
        <div className="text-center mt-3">
          <button
            className="btn btn-outline-dark "
            style={{
              backgroundColor: "#285979",
              color: "white",
              // borderColor: "#f0ad4e",
            }}
            onClick={() => setDisplayedProducts(displayedProducts + 3)}
          >
            Show More
          </button>
        </div>
      )}

      {displayedProducts > featuredProducts.length && (
        <div className="text-center mt-3">
          <button
            className="btn btn-outline-dark"
            onClick={() => setDisplayedProducts(3)}
          >
            Show Less
          </button>
        </div>
      )}
    </div>
  );
};

export default FeaturedProducts;
