import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css";
import jatinLogo from "../AlIimage/jatinLogo.png";
import facebook from "../AlIimage/facebook.svg";
import instagram from "../AlIimage/instagram.svg";
import twitter from "../AlIimage/twitter.svg";
import linkedin from "../AlIimage/linkedin.svg";
import logo from "../AlIimage/logo.png";
import axios from "axios";

function Footer() {
  const [address, setAddress] = React.useState([]);
  const [copyright, setCopyright] = React.useState("");
  const [socialMedia, setSocialMedia] = React.useState([]);
  const [logoLink, setLogoLink] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [config, setConfig] = React.useState([]);

  const getConfigDetails = async () => {
    try {
      setLoading(true);
      const response = await axios.get("/api/config");
      setConfig(response?.data?.result);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  React.useEffect(() => {
    getConfigDetails();
  }, []);

  React.useEffect(() => {
    if (config.length > 0) {
      setAddress(config[0]?.address);

      setSocialMedia(config[0]?.socialMedia);

      setCopyright(config[0]?.copyrightMessage);

      setLogoLink(config[0]?.logo[0]?.Headerlogo);
    }
  }, [config]);

  console.log(logoLink);

  return (
    <div className="main-footer" id="footer">
      <div className="container">
        <div className="" style={{ margin: "1rem" }}>
          {/* Column1 */}
          <div className="">
            {(logoLink && (
              <img src={logoLink} alt="logo" width={"147px"} />
            )) || <img alt="logo" width={"147px"} />}
            <h4 className="mt-3 mb-2">{/*<b>Address</b>*/}</h4>
            {/*<ul className="list-unstyled">
              <li>
                <span className="shopname">Mumbai Shop</span> No: 63,Mezanine
              </li>
              <li>Floor,Popular Arcade,C.H.S.Ltd.Tata Road</li>
              <li>No: 2,Opera HouseNear</li>
              <li>Panchratna.Mumbai - 400004r</li>
  </ul>*/}
            <div className="mt-3">
              {address.length > 0 && (
                <ul className=" list-unstyled">
                  <li>
                    <span
                      className="shopname"
                      style={{ fontFamily: "Raleway" }}
                    >
                      {address[0]?.addressLine1}
                    </span>
                  </li>
                  <li style={{ fontFamily: "Raleway" }}>
                    {address[0].addressLine2} {address[0]?.city}
                  </li>
                  <li style={{ fontFamily: "Raleway" }}>
                    {address[0].state} - {address[0].pincode}.
                  </li>
                </ul>
              )}
            </div>
          </div>
          {/* Column2 */}
          {/*<div className="col-sm-2 md-2 ">
            <h4 className="mt-4">
              <b>
                <Link className="text-decoration-none text-black" to="/diamond">
                  Diamond
                </Link>
              </b>
            </h4>
            <ul className="list-unstyled">
              <li>
                <Link className="text-decoration-none text-black" to="/rings">
                  Rings
                </Link>
              </li>
              <li>
                <Link className="text-decoration-none" to="/bracelets">
                  Bracelets
                </Link>
              </li>
              <li>
                <Link className="text-decoration-none" to="/earrings">
                  Earrings
                </Link>
              </li>
              <li>
                <Link className="text-decoration-none" to="/jhumkas">
                  Jhumkas
                </Link>
              </li>

              <li>
                <Link className="text-decoration-none" to="/mangalsutra">
                  Mangalsutra
                </Link>
              </li>

              <li>
                <Link className="text-decoration-none" to="/pendants">
                  Pendant
                </Link>
              </li>
              <li>
                <Link className="text-decoration-none" to="/chains">
                  Chain
                </Link>
              </li>
              <li>
                <Link className="text-decoration-none" to="/bangles">
                  Bangles
                </Link>
              </li>
            </ul>
</div>*/}

          {/* Column3*/}
          {/* <div className="col-sm-2 md-2">
            <ul className="list-unstyled">
              <li>
                <h4 className="mt-4">
                  <Link className="text-decoration-none" to="/kundan">
                    <b>Kundan</b>
                  </Link>
                </h4>
              </li>
              <li>
                <h4 className="mt-4">
                  <Link className="text-decoration-none" to="/polki">
                    <b>Polki</b>
                  </Link>
                </h4>
              </li>
            </ul>
</div>*/}
          {/* Column4 */}
          {/*<div className="col-sm-2 md-2">
            <ul className="list-unstyled">
              <li>
                <h4 className="mt-4">
                  <Link className="text-decoration-none" to="/about">
                    <b>About us</b>
                  </Link>
                </h4>
              </li>
              <li>
                <h4 className="mt-4">
                  <Link className="text-decoration-none" to="/contact">
                    <b>Contact</b>
                  </Link>
                </h4>
              </li>
            </ul>
</div>*/}

          {/* <div className="col">
            <h4 className="mt-4">
              <b>Policies</b>
            </h4>
            <ul className="list-unstyled">
              <li>
                <Link className="text-decoration-none" to="#">
                  Cancellation Policy
                </Link>
              </li>
              <li>
                <Link className="text-decoration-none" to="#">
                  Privacy Policy
                </Link>
              </li>
              <li>
                <Link className="text-decoration-none" to="#">
                  Returns & Exchange
                </Link>
              </li>
              <li>
                <Link className="text-decoration-none" to="#">
                  Refund Policy
                </Link>
              </li>

              <li>
                <Link className="text-decoration-none" to="#">
                  Shipping Policy
                </Link>
              </li>
              <li>
                <Link className="text-decoration-none" to="#">
                  Terms of Use
                </Link>
              </li>
            </ul>
          </div>
  */}
        </div>
      </div>

      <div className="container">
        <div className="row  " style={{ margin: "0.5rem" }}>
          <div className="col-sm-6">
            <p style={{ fontFamily: "Raleway" }}>{copyright}</p>
          </div>
          {socialMedia.length > 0 && (
            <div className="col-sm-4 d-flex justify-content-end social-icons">
              {/* {socialMedia[0].linkedin && (
                <li>
                  <a href={socialMedia[0].linkedin} target="_blank">
                    <img src={linkedin} alt="linkedin"></img>
                  </a>
                </li>
              )} */}
              {socialMedia[0].facebook && (
                <li>
                  <a href={socialMedia[0].facebook} target="_blank">
                    <img src={facebook}></img>
                  </a>
                </li>
              )}
              {socialMedia[0].instagram && (
                <li>
                  <a href={socialMedia[0].instagram} target="_blank">
                    <img src={instagram}></img>
                  </a>
                </li>
              )}
              {socialMedia[0].twitter && (
                <li>
                  <a href={socialMedia[0].twitter} target="_blank">
                    <img src={twitter}></img>
                  </a>
                </li>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Footer;
