import React from "react";
import { Container } from "react-bootstrap";
import img1 from "../AlIimage/signatureLooksImgs/riwaaz/9X4A5713.jpg";
import img2 from "../AlIimage/signatureLooksImgs/riwaaz/9X4A5734.jpg";
import img3 from "../AlIimage/signatureLooksImgs/riwaaz/после   9X4A5625 копия.jpg";
import img4 from "../AlIimage/signatureLooksImgs/riwaaz/после   9X4A5701 копия.jpg";
import img5 from "../AlIimage/signatureLooksImgs/riwaaz/после 9X4A5743 копия.jpg";
import axios from "axios";
import { useMediaQuery } from "react-responsive";
const imagees = [img1, img2, img3, img4, img5];
const Riwaaz = ({ btnClick }) => {
  const isSmallScreen = useMediaQuery({ maxWidth: 600 });
  return (
    <div style={{ marginTop: "0rem" }}>
      <Container>
        {/* <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <p className="title">Riwaaz</p>
          <div style={{ marginTop: "-1rem" }}>
            <button
              onClick={() => {
                window.open(btnClick);
              }}
           
              className="custom-button1"
            >
              {`Click here to shop this look`.toUpperCase()}
            </button>
           
          </div>
        </div> */}
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <p className="title">Riwaaz</p>
          {/* <div style={{ marginTop: "-1rem" }}>
            <button
              onClick={() => {
                window.open(btnClick);
              }}
              
              className="custom-button1"
            >
              {`Click here to shop this look`.toUpperCase()}
            </button>
            
          </div> */}
        </div>

        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            gap: "1rem",
            margin: "0rem 1rem",
          }}
        >
          {imagees.map((item, i) => (
            <div
              style={{
                position: "relative",
                width: "30%",

                marginBottom: "1rem",
              }}
            >
              <img src={item} alt="nazakath" style={{ width: "100%" }} />
              <i
                onClick={() => {
                  window.open(btnClick);
                }}
                class="far fa-heart"
                style={{
                  position: "absolute",
                  top: "5px",
                  cursor: "pointer",
                  right: "5px",
                  fontSize: isSmallScreen ? "20px" : "34px",
                  color: "#285979",
                }}
              ></i>
            </div>
          ))}
        </div>
      </Container>
    </div>
  );
};

export default Riwaaz;
