import React, { useState, useEffect } from "react";
import axios from "axios";
import loadmore from "../AlIimage/loadmore.png";
import loadless from "../AlIimage/loadless.png";
import line from "../AlIimage/horizontalline.png";
import { Carousel, Col, Container, Row } from "react-bootstrap";
import CelebritiesComponent from "./celebritiesComponent";

const Celebrities = () => {
  const [celebrities, setCelebrities] = useState([]);
  const [loading, setLoading] = useState(false);
  const [displayedCelebrities, setDisplayedCelebrities] = useState(3);

  const getAllCelebrities = async () => {
    setLoading(true);
    try {
      const response = await axios.get("/api/celebrity/getAll");
      //featured celebrities
      const filteredCelebrities = response.data.celebrity.filter(
        (celebrity) => celebrity.setAsFeatured === true
      );
      // non-featured celebrities
      const nonFeaturedCelebrities = response.data.celebrity.filter(
        (celebrity) => celebrity.setAsFeatured === false
      );
      // sort featured celebrities by updatedAt
      filteredCelebrities.sort((a, b) => {
        return new Date(b.updatedAt) - new Date(a.updatedAt);
      });
      // sort non-featured celebrities by updatedAt
      nonFeaturedCelebrities.sort((a, b) => {
        return new Date(b.updatedAt) - new Date(a.updatedAt);
      });
      // concat featured and non-featured celebrities
      const allCelebrities = filteredCelebrities.concat(nonFeaturedCelebrities);
      setCelebrities(allCelebrities);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getAllCelebrities();
  }, []);
  console.log(celebrities[3]);

  return (
    <div
      style={{
        backgroundColor: "#E1F3FF",
        paddingBottom: "2rem",
      }}
    >
      <div style={{ paddingTop: "0.5rem" }}>
        <span className="title">Celebrities in Jatin Mor Jewels</span>

        <Container style={{ padding: 0 }}>
          <Row style={{ margin: "0rem 1rem" }}>
            {loading && !celebrities.length && (
              <div className="d-flex justify-content-center mt-5">
                <div className="spinner-border" role="status">
                  <span className="sr-only"></span>
                </div>
              </div>
            )}
            {!loading && !celebrities.length && (
              <div className="d-flex justify-content-center">
                <h4>No Celebrities Found</h4>
              </div>
            )}
            <Col>
              <Carousel>
                {celebrities &&
                  celebrities.map((celebrity, index) => (
                    <Carousel.Item interval={3000} key={index}>
                      <Row>
                        <Col xs={12} md={4}>
                          <CelebritiesComponent src={celebrity.image[0].url} />
                        </Col>
                        <Col xs={12} md={4} className="d-none d-md-block">
                          <CelebritiesComponent
                            src={
                              celebrities[(index + 1) % celebrities.length]
                                ?.image[0].url
                            }
                          />
                        </Col>
                        <Col xs={12} md={4} className="d-none d-md-block">
                          <CelebritiesComponent
                            src={
                              celebrities[(index + 2) % celebrities.length]
                                ?.image[0].url
                            }
                          />
                        </Col>
                      </Row>
                    </Carousel.Item>
                  ))}
              </Carousel>
            </Col>
          </Row>
        </Container>
        {/* <div className="grid" style={{ marginRight: "1rem" }}>
          {loading && !celebrities.length && (
            <div className="d-flex justify-content-center mt-5">
              <div className="spinner-border" role="status">
                <span className="sr-only"></span>
              </div>
            </div>
          )}
          {!loading && !celebrities.length && (
            <div className="d-flex justify-content-center">
              <h4>No Celebrities Found</h4>
            </div>
          )}
          {celebrities.slice(0, displayedCelebrities).map((celebrity) => (
            <div
              key={celebrity._id}
              className="card   item shadow-box d-flex align-items-center justify-content-center"
              style={{
                width: "100%",
                height: "100%",
                margin: "10px",
                // overflow: "hidden",
              }}
            >
              <img
                src={celebrity.image[0].url}
                className="card-img-top"
                alt="..."
                style={{
                  width: "100%",
                  height: "100%",

                  objectFit: "cover",
                }}
              />
            </div>
          ))}
        </div>

        {displayedCelebrities < celebrities.length && (
          <div className="d-flex justify-content-center my-4">
            <img
              src={loadmore} // Replace with the path to your image
              alt="Load More"
              className="img-clickable"
              style={{ width: "35%", cursor: "pointer" }}
              onClick={() =>
                setDisplayedCelebrities(
                  displayedCelebrities + celebrities.length - 3
                )
              }
            />
          </div>
        )}
        {displayedCelebrities === celebrities.length && (
          <div className="d-flex justify-content-center my-4">
            <img
              src={loadless} // Replace with the path to your image
              alt="Load More"
              className="img-clickable"
              style={{ width: "35%", cursor: "pointer" }}
              onClick={() => setDisplayedCelebrities(3)}
            />
          </div>
        )} */}

        {/* {displayedCelebrities === celebrities.length && (
          <div className="d-flex justify-content-center">
            <button
              className="btn btn-outline-dark"
              onClick={() => setDisplayedCelebrities(3)}
            >
              Load Less
            </button>
          </div>
        )} */}
      </div>
    </div>
  );
};

export default Celebrities;
