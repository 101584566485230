import axios from "axios";
import React, { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import whatappIcon from "../AlIimage/2.png";

const ContactUs = () => {
  const [data, setData] = useState({
    name: "",
    EmailOrMobile: "",
    message: "",
  });

  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    e.preventDefault();
    setData((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      data.name.trim() === "" ||
      data.EmailOrMobile.trim() === "" ||
      data.message.trim() === ""
    ) {
      alert("Fill all mandatory fields");
      return;
    }
    setLoading(true);
    const formData = new FormData();
    formData.set("name", data.name);
    formData.set("EmailOrMobile", data.EmailOrMobile);
    formData.set("message", data.message);
    axios
      .post(`/api/contact/request/new/`, formData)
      .then((res) => {
        toast.success("Contact Requests added successfully");
        setData({
          name: "",
          EmailOrMobile: "",
          message: "",
        });

        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        const message = err.response?.data?.message || "Something went wrong!";
        alert(message);
      });
  };

  return (
    <div style={{ background: "#e1f3ff", width: "100vw", overflowX: "hidden" }}>
      <div className="container">
        <div className="row p-4">
          <div className="col-md-4">
            <div
              className="card p-3"
              style={{
                height: "100%",
                border: "10px solid #285979",
                boxShadow: "10 10 10px #835e5a",

                verticalAlign: "middle",
              }}
            >
              <div className="card-body">
                <p
                  className="card-title mb-3 "
                  style={{
                    color: "#285979",
                    fontWeight: "bolder",
                    fontSize: "1.5rem",
                  }}
                >
                  Get in touch
                </p>

                <form className="">
                  <div className="form-group  ">
                    <input
                      type="text"
                      className="form-control mb-3  "
                      id="name"
                      placeholder="Name"
                      value={data.name}
                      maxLength={25}
                      onChange={(e) => handleChange(e)}
                    />

                    <input
                      type="email"
                      className="form-control mb-3  "
                      id="EmailOrMobile"
                      placeholder="Email address"
                      value={data.EmailOrMobile}
                      onChange={(e) => handleChange(e)}
                    />

                    <textarea
                      className="form-control mb-3   "
                      id="message"
                      rows="3"
                      placeholder="Enter your message"
                      value={data.message}
                      onChange={(e) => handleChange(e)}
                    ></textarea>

                    <button
                      type="button"
                      style={{
                        padding: "12px",
                        background: "#285979",
                        color: "#fff",

                        border: "none",
                        cursor: "pointer",
                        borderRadius: "10px",
                      }}
                      // className="btn btn-submit mt-3 m-3"
                      onClick={handleSubmit}
                      disabled={loading}
                    >
                      Submit
                    </button>
                    <ToastContainer />
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="col-md-8">
            <div className="grid ">
              <div
                className="grid__item p-2 m-2"
                style={{
                  textAlign: "center",
                }}
              >
                <i
                  className="fas fa-envelope"
                  style={{ fontSize: "36px", color: "#285979" }}
                ></i>

                <p style={{ color: "#285979" }}>
                  For all enquiries write to us at
                  <br />
                  <b>admin@jatinmorjewels.com</b>
                </p>
              </div>
              <div
                className="grid__item p-2 m-2"
                style={{
                  textAlign: "center",
                }}
              >
                <i
                  className="fas fa-phone"
                  style={{ fontSize: "36px", color: "#285979" }}
                ></i>

                <p style={{ color: "#285979" }}>
                  Call us at
                  <br />
                  <b>+91-9100006969</b>
                  <br />
                  <b>+91-9100004545</b>
                </p>
              </div>
              <div
                className="grid__item p-2 m-2"
                style={{
                  textAlign: "center",
                }}
              >
                {/* <i
                  className="fa fa-whatsapp"
                  style={{ fontSize: "36px", color: "#285979" }}
                ></i> */}
                <img
                  src={whatappIcon}
                  style={{ fontSize: "36px", color: "#285979", width: "36px" }}
                  alt="one"
                />

                <p style={{ color: "#285979" }}>
                  For whatsapp enquiries
                  <br />
                  <b>+91-9100006969</b>
                </p>
              </div>
            </div>
            {/* map of hyderabad here */}
            <div className="map">
              {/* <iframe
                title="map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3806.880547821711!2d78.3857803148849!3d17.4439999880481!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb8b0b0b0b0b0b%3A0x1b0b0b0b0b0b0b0b!2sMor%20Jwellers!5e0!3m2!1sen!2sin!4v1626958518803!5m2!1sen!2sin"
                width="100%"
                height="350"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
              ></iframe> */}
              <iframe
                title="map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d121812.91577467154!2d78.37067063836992!3d17.428402967239286!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb90b57afd46af%3A0xba16b1f42fe0c14c!2sJATIN%20MOR%20JEWELS!5e0!3m2!1sen!2sin!4v1710918638655!5m2!1sen!2sin"
                width="100%"
                height="350"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
