import React from "react";
import Policies from "./Policies";

const CancellationPolicy = () => {
  const title = "Cancellation policy";
  const content = (
    <>
      <p>
        There may be certain orders that Jatin Mor Jewels cannot accept, and
        therefore, we reserve the right, at our sole discretion, to refuse or
        cancel any order. Some reasons may include limitation on quantity
        available for purchase; errors in pricing or product information or
        certain issues identified by our fraud avoidance department or any other
        issue which Jatin Mor identifies for not accepting the order. We also
        reserve the right to ask for additional information for accepting orders
        in certain cases. We will notify you in case your order has been
        cancelled fully or partially or if any additional information is
        required to accept your order.
      </p>
      <p>
        Cancellation by Customer:Once an order is placed, it can only be
        cancelled before it is shipped to the provided destination. Once the
        cancellation request is received, we will initiate the process of
        refunding the amount to your account via the same payment mode that you
        had used to make the transaction or through cheque (amount would be
        refunded through cheque, only in cases where the debit/credit card used
        by the customer while placing the order is not in use). Once the refund
        process is initiated, you will receive the refund amount directly in
        your account or through cheque.
      </p>
      <p>
        {" "}
        NOTE: If the amount has been deducted from your account but the
        transaction failed, we will refund the amount to your account at the
        earliest. For any assistance, customers can call our customer care @
        040-23405786 or email us at{" "}
        <u>
          <a href="mailto:support@jatinmorjewels.com">
            {" "}
            support@jatinmorjewels.com
          </a>
        </u>
        .
      </p>
    </>
  );
  return (
    <div>
      <Policies title={title} content={content} />
    </div>
  );
};

export default CancellationPolicy;
