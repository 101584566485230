import React from "react";

const GetExpertOpinion = ({ name, code }) => {
  return (
    <div>
      <div
        className="card"
        style={{ width: "80%", margin: "auto", padding: "5%" }}
      >
        <div className="card-body">
          <h2
            className="text-center container-title"
            style={{
              marginTop: "0",
              fontFamily: "Open sans",
              fontSize: "2.5rem",
            }}
          >
            Get Expert Opinion
          </h2>
          <form>
            <style>
              {`
          @media (max-width: 768px) {
            .form-flex {
              flex-direction: column;
            }
          }
        `}
            </style>
            <div
              className="d-flex form-flex"
              style={{ justifyContent: "space-between" }}
            >
              <div className="form-group mb-2">
                <label htmlfor="jewelryName">Jewelry Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="jewelryName"
                  aria-describedby="jewelryName"
                  placeholder="jewelryName"
                  value={name}
                />
              </div>

              <div className="form-group mb-2">
                <label htmlfor="productCode">Product Code</label>
                <input
                  type="text"
                  className="form-control"
                  id="productCode"
                  aria-describedby="productCode"
                  placeholder="productCode"
                  value={code}
                />
              </div>
            </div>

            <div
              className="d-flex form-flex"
              style={{ justifyContent: "space-between" }}
            >
              <div className="form-group mb-2">
                <label htmlfor="productCode">Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  aria-describedby="name"
                  placeholder="Enter your name here"
                />
              </div>

              <div className="form-group mb-2">
                <label htmlfor="productCode">Email</label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  aria-describedby="email"
                  placeholder="Enter your email here"
                />
              </div>
            </div>

            <div className="form-group mb-2">
              <label htmlfor="productCode">Phone Number</label>
              <input
                type="text"
                className="form-control"
                id="phoneNumber"
                aria-describedby="phoneNumber"
                placeholder="Enter your phone number here"
              />
            </div>

            <div className="form-group mb-2">
              <label htmlfor="productCode">Message</label>
              <textarea
                type="text"
                className="form-control"
                id="message"
                aria-describedby="message"
                placeholder="Enter your message here"
              />
            </div>
          </form>

          <button
            type="button"
            className="btn text-white product-button"
            style={{ margin: "auto", width: "50%" }}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default GetExpertOpinion;
