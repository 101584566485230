import React from "react";
import Policies from "./Policies";

const ShippingPolicy = () => {
  const title = "Shipping Policy";
  const content = (
    <>
      <p>
        In India, Jatin Mor serves to selected cities. Please check if we
        deliver to your pincode in shopping cart checkout page. If there is no
        courier service available in your area, we do apologize for the
        inconvenience caused.We also provide international delivery to the
        following countries: <b>Australia, Singapore, UAE, UK, USA.</b>
      </p>{" "}
      <b>NOTE:</b>{" "}
      <p>
        At present, we do not have the option to deliver a single order to
        multiple destinations. You will need to place different orders.
      </p>
      <b>Shipping & Handling Charges:</b>
      <p>
        {" "}
        Domestic Order: We provide free shipping within India.M. <br />
        International Order: In case of an order which is to be shipped outside
        India customer has to bear the shipping & handling charges plus any duty
        as applicable at the destination.
        <br /> Custom Clearance: All shipments of Jewellery exported from India
        need to be cleared by the Indian Customs office. Products along with
        their complete documentation are filed with the authorized officer of
        the Indian Customs office for inspection, examination and assessment and
        only then delivered to your shipping address. Hence, please ignore the
        message on the package section which mentions: “Please do not accept if
        the package is received in Open/ Tampered condition”. You will find the
        package sealed with a label saying “Opened for Customs”.
      </p>{" "}
      <p>
        <b>Shipping Partner:</b> Our domestic logistic partner is BVC Logistics.
      </p>
      <p>
        {" "}
        <b>NOTE:</b> In case of International order, the customer has to pay the
        shipping charges at the time of placing the order. Also, custom duty &
        taxes levied by destination country has to be borne by the customer and
        needs to be paid to the logistic partner at the time of delivery. Please
        note that all consignments are on Delivery Duty Unpaid (DDU) basis.{" "}
      </p>
      <b> Time To Deliver:</b>
      <p>
        {" "}
        The time taken for delivery tends to vary according to the destination;
        however, we make our best efforts to ensure that the order is delivered
        on time. <br />
        Delivery Time for Domestic Orders: It takes 5-7 working days from the
        day of order confirmation to deliver the products within India given
        that the delivery is not delayed due to governmental authority or any
        other entity acting on behalf of the government or acting as per the
        directions of the government. In the unlikely event that the delivery
        period exceeds the stipulated time, the order is cancelled and the
        customer is notified of the same. In such cases, the refund is made
        directly to the customer’s bank account using the same mode the payment
        was made. Jatin Mor partners with reputed courier agencies to ensure
        prompt and secure delivery. Since the delivery of products is address
        specific, please ensure that the address entered while placing the order
        is correct. Important Note: In case the office address is provided for
        delivery, please make sure that the department details, employee number
        and direct landline numbers are also provided to prevent any last-minute
        hassles and failed delivery .<br /> To prevent misplaced delivery,
        please keep one of the following identity cards for verification:{" "}
        <ul>
          <li>Pan card </li>
          <li>Driving License</li>
          <li> Passport</li>
          <li> Voter identification card </li>
          <li>Unique Identification Card (Aadhaar)</li>
        </ul>
        Delivery Time for International Orders: The product is delivered within
        7-21 working days outside India from the day of order confirmation,
        provided such delivery is not delayed by any governmental authority or
        any other entity acting on behalf of the government or acting as per the
        directions of the government. In the unlikely event that we fail to
        deliver your order within the stipulated period, we shall cancel the
        order and notify you regarding the same. In such cases, the refund will
        be made directly to your account via the same mode through which the
        payment was made. We only partner with reputed courier agencies to
        ensure that the products reach you promptly and in perfect condition. We
        also ensure that the delivery is made to the recipient and thus require
        the identity proof for verification.
      </p>
      <p>
        {" "}
        <b>PLEASE NOTE:</b> Recipient needs to produce passport or driving
        license for receiving the delivery.For any customer assistance, you can
        call on our toll-free number 040-23405786 or write to
        support@jatinmorjewels.com.Transit Insurance: All goods will be fully
        insured by Jatin Mor Jewels until they reach you, so your purchase is
        100% safe.
      </p>
    </>
  );
  return (
    <div>
      <Policies title={title} content={content} />
    </div>
  );
};

export default ShippingPolicy;
