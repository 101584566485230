import React, { useEffect, useState } from "react";

import contactus from "../AlIimage/contact us(1).png";

import styled from "styled-components";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import ImageSlider from "./ImageSlider";

// import { HorizontalScroll } from "../../Utils/HorizontalScroll";

// const HorizontalSection = styled.section`
//   position: relative;
//   width: 100%;
//   min-height: 100vh;
//   background-color: #000;
// `;

const BumperSection = styled.section`
  text-align: center;

  color: #fff;
`;

//padding : y-axis x-axis

const CardsContainer = styled.div`
  position: relative;
  height: 300px;
  padding: 0 0 0 150px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
`;

const SampleCard = styled.div`
  height: 300px;
  width: 95%;
  background-color: #000;
  flex-shrink: 0;
`;

// const SampleCards = React.memo(() =>
//   Array(5)
//     .fill(0)
//     .map((_e, i) => <SampleCard key={`sampleCard-${i}`} />)
// );

/*<div className="container" style={{ marginTop: "5rem" }}>
      <h1 className="text-center">Our Collections</h1>
      <div
        className="mt-4 "
        style={{
          position: "relative",
          display: "inline",
          backgroundColor: "rgba(217, 217, 217, 1)",
        }}
      >
        <img
          className="OurCollections"
          style={{ padding: "5%" }}
          src={blur1}
          alt=""
          width="70%"
        />
        <img
          className="float-end OurCollections"
          style={{ position: "absolute", top: "0", right: "-18%" }}
          src={Kundan}
          alt=""
          width="37%"
        />
      </div>
    </div>*/

const OurCollection = () => {
  const [loading, setLoading] = useState(true);
  const [ourCollections, setOurCollections] = useState([]);
  const navigate = useNavigate();
  const [socialMedia, setSocialMedia] = React.useState([]);

  // const [loading, setLoading] = React.useState(false);
  const [config, setConfig] = React.useState([]);

  const getConfigDetails = async () => {
    try {
      const response = await axios.get("/api/config");
      setConfig(response?.data?.result);
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    getConfigDetails();
  }, []);

  React.useEffect(() => {
    if (config.length > 0) {
      setSocialMedia(config[0]?.socialMedia);
    }
  }, [config]);

  const getOurCollections = async () => {
    try {
      const response = await axios.get(`/api/ourCollection/getAll`);
      setOurCollections(response.data?.ourCollection);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getOurCollections();
  }, []);

  if (loading) {
    return (
      <div className="d-flex justify-content-center mt-5">
        <div className="spinner-border" role="status">
          <span className="sr-only"></span>
        </div>
      </div>
    );
  }

  return (
    <>
      <div style={{ background: "#fefeff", marginTop: "-1.0rem" }}>
        <div>
          <span className="title">Collection</span>
          <div className="container" style={{ padding: 0 }}>
            <ImageSlider ourCollections={ourCollections} />
          </div>
        </div>
      </div>

      {/* <div style={{ background: "#fefeff", marginTop: "-1rem" }}>
        <span className="title">Collection</span>

       
        <div
          className="container"
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div
            
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: "20px",
              marginTop: "-1rem",
             
              justifyContent: "center",
              maxWidth: "1200px" ,
              width: "100%",
            }}
          >
            {ourCollections.map((collection, index) => (
              <Link
                to={`/collection/${
                  collection.name.charAt(0).toUpperCase() +
                  collection.name.slice(1)
                }`}
                key={collection._id}
              >
                <div
                  className="item"
                  style={{
                    flex: "1 0 30%",
                    display: "flex",
                 
                    marginBottom: "8px",
                  }}
                >
                  <img
                    src={collection.image[0].url}
                    alt={collection.name}
                    style={{
                      width: "100%",
                      height: "360px",
                      objectFit: "cover",
                    }}
                  />
                </div>
              </Link>
            ))}
          </div>
        </div>
       
      </div> */}
    </>
  );
};

export default OurCollection;
