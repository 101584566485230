import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import videocall from "../AlIimage/videocall.svg";
import whatsapp from "../AlIimage/whatsapp.svg";
import phoneCall from "../AlIimage/phone-call.svg";
import CloseButton from "react-bootstrap/CloseButton";
import customerSupportIcon from "../AlIimage/customer-support.svg";
import BookAppointment from "../BookAppointment/BookAppointment";
import image1 from "../AlIimage/1.png";
import image2 from "../AlIimage/2.png";
import image3 from "../AlIimage/3.png";
import image4 from "../AlIimage/4.png";
import cross from "../AlIimage/Untitled design.png";
import axios from "axios";

const CustomerSupportModal = ({ modalShow, setModalShow }) => {
  const [socialMedia, setSocialMedia] = React.useState([]);

  const [loading, setLoading] = React.useState(false);
  const [config, setConfig] = React.useState([]);

  const getConfigDetails = async () => {
    try {
      setLoading(true);
      const response = await axios.get("/api/config");
      setConfig(response?.data?.result);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  React.useEffect(() => {
    getConfigDetails();
  }, []);

  React.useEffect(() => {
    if (config.length > 0) {
      setSocialMedia(config[0]?.socialMedia);
    }
  }, [config]);

  const [show, setShow] = useState(false);
  const number = "14155238886"; // twilio number

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  console.log(show);
  return (
    <Modal
      show={modalShow}
      onHide={() => setModalShow(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      animation
      dialogClassName="modal-100w"
      style={{
        // Add the following style prop to change the close button color
        ".btnClose": { color: "white" },
      }}
    >
      {/* <Modal.Footer closeButton>
        <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>{" "}
      </Modal.Footer> */}
      {/* <Modal.Body style={{ paddingTop: "5%" }}>
        <div className="row w-100">
          <div className="col-sm-4 md-4 center-column">
            <button
              className="scaleonhover btn mt-2"
              onClick={handleShow}
              style={{
                color: "white",
                border: "1px dotted white",
                width: "60%",
                background: "transparent",
              }}
            >
              <img src={videocall} alt="video call" /> &nbsp;Video Call
            </button>
            <Modal
              show={show}
              onHide={handleClose}
              aria-labelledby="contained-modal-title-vcenter"
              centered
              closeButton
              style={{
                backgroundColor: "rgba(0,0,0,0.5)",
              }}
            >
              <Modal.Header
                closeButton
                style={{ backgroundColor: "#4f1e1a" }}
              />
              <Modal.Body
                closeButton
                style={{ backgroundColor: "#fff", padding: "0" }}
              >
                <BookAppointment />
              </Modal.Body>
            </Modal>
          </div>
          <div className="col-sm-4 md-4 center-column">
            <button
              className="scaleonhover btn mt-2"
              onClick={() => {
                window.open(`tel:${socialMedia[0]?.callNumber}`);
              }}
              style={{
                color: "white",
                border: "1px dotted white",
                width: "60%",
                background: "transparent",
              }}
            >
              <img src={phoneCall} alt="phone call" /> &nbsp;Call
            </button>
          </div>
          <div className="col-sm-4 md-4 center-column">
            <button
              className="scaleonhover btn mt-2"
              onClick={() => {
                window.open(
                  `https://wa.me/${socialMedia[0]?.whatsappNumber}?text=Hi%20I%20am%20interested%20in%20your%20products`
                );
              }}
              style={{
                color: "white",
                border: "1px dotted white",
                width: "60%",
                background: "transparent",
              }}
            >
              <img src={whatsapp} alt="whatsapp" /> &nbsp;Whatsapp
            </button>
          </div>
        </div>
      </Modal.Body> */}
      <Modal.Body style={{ margin: 0, padding: "0.5rem" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            margin: "1rem 0rem ",
          }}
        >
          {/* <i className=" fas fa-whatsapp" style={{ color: "#285979" }}></i> */}
          <img
            src={image1}
            onClick={() => {
              window.open(`tel:${socialMedia[0]?.callNumber}`);
            }}
            style={{
              width: "1.8rem",
              height: "1.8rem",
              marginBottom: "1rem",
              cursor: "pointer",
            }}
            alt="one"
          />
          <img
            src={image2}
            onClick={() => {
              window.open(
                `https://wa.me/${socialMedia[0]?.whatsappNumber}?text=Hi%20I%20am%20interested%20in%20your%20products`
              );
            }}
            style={{
              width: "2rem",
              height: "2rem",
              marginBottom: "1rem",
              cursor: "pointer",
            }}
            alt="one"
          />
          <img
            src={image3}
            onClick={() =>
              window.open(
                "https://www.instagram.com/jatinmorjewels/?hl=en",
                "_blank"
              )
            }
            style={{
              width: "2rem",
              height: "2rem",
              marginBottom: "1rem",
              cursor: "pointer",
            }}
            alt="one"
          />
          <img
            src={image4}
            onClick={handleShow}
            style={{
              width: "2rem",
              height: "2rem",
              marginBottom: "1rem",
              cursor: "pointer",
            }}
            alt="one"
          />
          <img
            src={cross}
            onClick={() => setModalShow(false)}
            style={{
              width: "2rem",
              height: "2rem",

              cursor: "pointer",
            }}
            alt="one"
          />

          {/* <i
            className="fas fa-phone"
            onClick={handleShow}
            style={{ color: "#285979" }}
          ></i> */}
          <Modal
            show={show}
            onHide={handleClose}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            closeButton
            style={{
              backgroundColor: "rgba(0,0,0,0.5)",
            }}
          >
            <Modal.Header closeButton style={{ backgroundColor: "#285979" }} />
            <Modal.Body
              closeButton
              style={{ backgroundColor: "#fff", padding: "0" }}
            >
              <BookAppointment />
            </Modal.Body>
          </Modal>
          {/* <i
            className="fas fa-times "
            style={{
              color: "#285979",
              fontSize: "2rem",
              borderRadius: "50%",
              padding: "0.2rem 0.3rem",
              border: "2px solid #285979 ",
              cursor: "pointer",
            }}
            onClick={() => setModalShow(false)}
          ></i> */}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CustomerSupportModal;
