import React from "react";
import Catalogue from "../Catalogue";
import { Link, useParams } from "react-router-dom";
import axios from "axios";

const ProductCollection = () => {
  const [loading, setLoading] = React.useState(true);
  const [productCollection, setProductCollection] = React.useState([]);
  const { collection } = useParams();

  const getProductCollection = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `/api/productCollection/getOne/${collection}`
      );

      setProductCollection(response.data.productCollection);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  React.useEffect(() => {
    getProductCollection();
  }, [collection]);

  const titleText = (
    <div>
      <p>{productCollection.description}</p>
    </div>
  );

  return (
    <>
      {loading && <div>Loading...</div>}
      {!loading && productCollection && (
        <div>
          <Catalogue
            title={productCollection.name}
            titleText={titleText}
            titleImage={productCollection.image[0].url}
            category={""}
            productCollection={productCollection.name}
          />
        </div>
      )}
    </>
  );
};

export default ProductCollection;
