import React from "react";
import { Link, NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { ReactComponent as CircleHeart } from "../AlIimage/circle-heart.svg";
import { ReactComponent as CircleHeartRed } from "../AlIimage/circle-heart-red.svg";
import axios from "axios";
import FeaturedProducts from "../Home/FeaturedProducts";

const AllProducts = ({ category, productCollection, ...props }) => {
  const [isLiked, setIsLiked] = React.useState(false);

  const [products, setProducts] = React.useState([]);

  const [loading, setLoading] = React.useState(false);

  const getAllProducts = async () => {
    setLoading(true);
    try {
      const response = await axios.get("/api/product/getAll");

      setProducts(response.data.product);

      // setProducts(response.data.product);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  React.useEffect(() => {
    getAllProducts();
  }, []);

  console.log(products);
  return (
    <>
      <div className="row">
        <FeaturedProducts style={{ marginTop: "0" }} />
      </div>
      <div>
        <div className="row " style={{ margin: "0rem 1rem " }}>
          <table
            style={{ border: "1px solid black", borderCollapse: "collapse" }}
          >
            <tbody>
              <tr>
                <td
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                    color: "black",
                  }}
                >
                  <div className="dropdown" style={{ color: "black" }}>
                    <Link
                      className=" dropdown-toggle text-black text-decoration-none me-1"
                      href="#"
                      id="dropdownMenuButton1"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      style={{ color: "black" }}
                    >
                      Sort by
                    </Link>
                    <ul
                      className="dropdown-menu  dropdown-menu-dark"
                      aria-labelledby="navbarDarkDropdownMenuLink"
                    >
                      <li>
                        <a className="dropdown-item" href="#">
                          High to Low
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          Low to High
                        </a>
                      </li>
                    </ul>
                  </div>
                </td>
                <td
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  <div className="dropdown" style={{ color: "black" }}>
                    <Link
                      className=" dropdown-toggle text-black text-decoration-none me-1"
                      href="#"
                      id="dropdownMenuButton1"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      style={{ color: "black" }}
                    >
                      Color
                    </Link>
                    <ul
                      className="dropdown-menu  dropdown-menu-dark"
                      aria-labelledby="navbarDarkDropdownMenuLink"
                    >
                      <li>
                        <a className="dropdown-item" href="#">
                          Yellow
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          Gold
                        </a>
                      </li>
                    </ul>
                  </div>
                </td>
                <td
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  <div className="dropdown">
                    <Link
                      className=" dropdown-toggle text-black text-decoration-none me-1"
                      href="#"
                      id="dropdownMenuButton1"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      style={{ color: "black" }}
                    >
                      Price
                    </Link>
                    <ul
                      className="dropdown-menu  dropdown-menu-dark"
                      aria-labelledby="navbarDarkDropdownMenuLink"
                    >
                      <li>
                        <a className="dropdown-item" href="#">
                          300,000 - 500,000
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          500,000 - 700,000
                        </a>
                      </li>
                    </ul>
                  </div>
                </td>
                <td
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  <div className="dropdown">
                    <Link
                      className=" dropdown-toggle text-black text-decoration-none me-1"
                      href="#"
                      id="dropdownMenuButton1"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      style={{ color: "black" }}
                    >
                      Gender
                    </Link>
                    <ul
                      className="dropdown-menu  dropdown-menu-dark"
                      aria-labelledby="navbarDarkDropdownMenuLink"
                    >
                      <li>
                        <a className="dropdown-item" href="#">
                          Men
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          Women
                        </a>
                      </li>
                    </ul>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          className="container"
          style={{ margin: "auto", justifyContent: "center" }}
        >
          {!loading && products.length === 0 && (
            <div className="d-flex justify-content-center">
              <h2 style={{ color: "#285979", fontFamily: "Raleway" }}>
                No Products Found
              </h2>
            </div>
          )}
          {loading ? (
            <div className="d-flex justify-content-center">
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : (
            <div
              className="row"
              style={{
                margin: "auto",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {products.map((product) => (
                <div className="col-lg-3 sm-4 mt-2" key={product._id}>
                  <div className="card" style={{ margin: "0rem 1rem" }}>
                    <div className="card-header">
                      <strong style={{ fontFamily: "Hahmlet" }}>
                        {isLiked ? (
                          <CircleHeartRed
                            width="32"
                            height="32"
                            className="card-icon"
                          />
                        ) : (
                          <CircleHeart
                            width="32"
                            height="32"
                            className="card-icon"
                          />
                        )}
                      </strong>
                    </div>
                    <img
                      src={product.image[0].url}
                      width={315}
                      height={290}
                      className="card-img-top"
                      alt="..."
                    />
                    <div className="card-body"></div>
                    <div className="card-footer">
                      <strong style={{ fontFamily: "Hahmlet" }}>
                        {product.name}
                      </strong>
                      <p className="card-text">{product?.productCode}</p>
                      <strong style={{ fontFamily: "Hahmlet" }}></strong>
                    </div>
                    <NavLink
                      to={`/product/${product._id}`}
                      className="btn text-white product-button"
                      width="100%"
                    >
                      Know More
                    </NavLink>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default AllProducts;
