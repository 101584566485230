import React from "react";
import ReactDOM from "react-dom/client";
import axios from "axios";
import App from "./App";

const setupAxios = () => {
  //axios.defaults.baseURL = "https://famous-elk-loafers.cyclic.app/"; //cyclic-> famous-elk-loafers.cyclic.app/
  //axios.defaults.baseURL = "https://jatinmor-api.checkapp.one/";

  // axios.defaults.baseURL = "http://localhost:5000"; // local
  axios.defaults.baseURL = "https://jatinmorapi.onrender.com"; //  USE THIS  render-> https://jatinmorapi.onrender.com

  axios.defaults.headers = {
    "Cache-Control": "no-cache,no-store",
    Pragma: "no-cache",
    Expires: "0",
  };
};

setupAxios();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
