import React from "react";
import customerSupportIcon from "../AlIimage/newchaticon.png";
import CustomerSupportModal from "./CustomerSupportModal";
// import CloseButton from "react-bootstrap/CloseButton";
// import axios from "axios";

const CustomerSupport = () => {
  const [modalShow, setModalShow] = React.useState(false);
  const [closeState, setCloseState] = React.useState(false);
  const [width, setWidth] = React.useState("100px");

  React.useEffect(() => {
    if (closeState) {
      setWidth("20px");
    } else {
      setWidth("100px");
    }
  }, [closeState]);

  return (
    <div className="customer-support">
      <div
        className="customer-support-icon"
        style={{ width: "200px", zIndex: "1000" }}
      >
        {/* <button
          className="close-button "
          onClick={() => setCloseState(!closeState)}
          style={{
            background: "none",
            border: "none",
            outline: "none",
            cursor: "pointer",
            color: "green",
            width: "100px",
          }}
        >
          {closeState ? (
            <i className="fas fa-plus"></i>
          ) : (
            <i className="fas fa-times"></i>
          )}
        </button> */}
        {!modalShow && (
          <button
            className=""
            onClick={() => setModalShow(true)}
            style={{
              background: "none",
              border: "none",
              outline: "none",
              cursor: "pointer",
              width: "100px",
            }}
          >
            <img
              src={customerSupportIcon}
              alt="chat"
              // style={{ width: "80px" }}
              className="chaticon"
            />
          </button>
        )}
      </div>

      <CustomerSupportModal modalShow={modalShow} setModalShow={setModalShow} />
    </div>
  );
};

export default CustomerSupport;
