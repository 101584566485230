import React from "react";
import Policies from "./Policies";

const EasyReturn = () => {
  const title = "Easy return and exchange";
  const content = (
    <>
      <p>
        <b>Refund Policy:</b>
        <br /> For all Jewellery – 100% refund/exchange for the invoice value of
        the product within policy timeframe, this facility can be availed only
        one time. <br />
        Procedure for availing this Policy <br />
        Call our Customer Care team at 040-23405786 or email us at
        support@jatinmorjewels.com and place your exchange/ return request.
        <br /> We collect and store any information that you may enter on any of
        our web sites or provide to us in some other way. For example, when you
        place an order and/or sign-up for email communications and updates, we
        collect and store personal information so that we may provide the
        products and services that you have ordered or requested. Such
        information may include your name, address, age, date of birth, gender,
        education level, email address, telephone and fax numbers, credit card
        information and information about your interests and activities. You can
        choose not to provide certain types of information, but that may result
        in you being unable to use certain features of our website. We may also
        collect and store information about other people that you provide to us.
        For example, you may submit a persons name and address if you wish us to
        ship a product as a gift to a friend. "You agree and consent to receive
        all communications at the mobile number provided, even if this mobile
        number is registered under DND/NCPR list under TRAI regulations"
        <br /> We may also automatically collect and store certain types of
        non-personally identifiable information whenever you visit any of our
        web pages. For example, like most commercial shopping websites, we use
        ‘cookies’ and we obtain certain types of information whenever your web
        browser accesses JatinMorJewels.com.
      </p>
    </>
  );
  return (
    <div>
      <Policies title={title} content={content} />
    </div>
  );
};

export default EasyReturn;
