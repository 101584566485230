import React from "react";
import { useMediaQuery } from "react-responsive";

const CatalogueTitle = ({ title, titleText, titleImage, ...props }) => {
  const isSmallScreen = useMediaQuery({ maxWidth: 600 });
  return (
    <div style={{ width: "100vw", overflowX: "hidden" }}>
      <div className="row ">
        <div className="col-sm-6">
          <p
            className="container-title"
            style={{
              textAlign: "left",
              marginBottom: "1rem",
              fontFamily: "Felix Titling",
              fontWeight: "600",
              color: "#285979",
              padding: "1rem 1rem 0rem 1rem ",
            }}
          >
            {title}
          </p>
          <p
            style={{
              textAlign: "left",
              marginBottom: "1rem",
              padding: "0rem 1rem 0rem 1rem ",
              color: "#285979",
              fontFamily: "Raleway",
            }}
          >
            {titleText}
          </p>
        </div>
        {/* <div className="col-sm-1"></div> */}
        <div
          className="col-sm-6"
          style={{
            textAlign: isSmallScreen ? "center" : "",
          }}
        >
          <img
            src={titleImage}
            style={{
              maxHeight: "500px",
              maxWidth: "500px",
            }}
            alt="kundan collection"
          />
        </div>
      </div>
    </div>
  );
};

export default CatalogueTitle;
