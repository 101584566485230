import React, { useEffect, useState } from "react";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Rectangle1 from "../AlIimage/Rectangle1.png";
import Rectangle2 from "../AlIimage/Rectangle2.png";

import Rectangle3 from "../AlIimage/Rectangle3.png";
import Rectangle4 from "../AlIimage/Rectangle4.png";

import Rectangle5 from "../AlIimage/Rectangle5.png";

import "./CustomerSayPage.css";
import axios from "axios";
import { Spinner } from "react-bootstrap";

// set custom dot
const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
  const {
    carouselState: { currentSlide },
  } = rest;
  return (
    <div className="carousel-button-group d-flex justify-content-center mb-5">
      <svg
        onClick={() => previous()}
        className={(currentSlide === 0 ? "disable" : "") + " me-3 mt-5 "}
        width="16"
        height="30"
        viewBox="0 0 16 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.4012 1.8751C15.4008 2.37221 15.203 2.84881 14.8512 3.2001L5.26124 12.7901C4.97097 13.0803 4.74071 13.4248 4.58361 13.804C4.42652 14.1832 4.34566 14.5897 4.34566 15.0001C4.34566 15.4106 4.42652 15.817 4.58362 16.1962C4.74071 16.5754 4.97097 16.9199 5.26124 17.2101L14.8387 26.7939C15.1803 27.1475 15.3693 27.6211 15.365 28.1127C15.3607 28.6043 15.1635 29.0746 14.8159 29.4223C14.4683 29.7699 13.998 29.9671 13.5064 29.9714C13.0148 29.9756 12.5411 29.7866 12.1875 29.4451L2.60999 19.8689C1.3222 18.5785 0.59894 16.83 0.59894 15.007C0.598939 13.184 1.3222 11.4354 2.60999 10.1451L12.2 0.555102C12.4618 0.29308 12.7954 0.114493 13.1587 0.0418741C13.5219 -0.0307444 13.8985 0.00586141 14.241 0.147072C14.5835 0.288282 14.8764 0.527769 15.0829 0.835312C15.2894 1.14285 15.4002 1.50467 15.4012 1.8751Z"
          fill="black"
          fillOpacity="0.4"
        />
      </svg>
      <svg
        className="mt-5"
        onClick={() => next()}
        width="16"
        height="30"
        viewBox="0 0 16 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.598755 28.1249C0.599193 27.6278 0.797024 27.1512 1.14876 26.7999L10.7388 17.2099C11.029 16.9197 11.2593 16.5752 11.4164 16.196C11.5735 15.8168 11.6543 15.4103 11.6543 14.9999C11.6543 14.5894 11.5735 14.183 11.4164 13.8038C11.2593 13.4246 11.029 13.0801 10.7388 12.7899L1.16125 3.20615C0.819708 2.85252 0.630719 2.37889 0.634991 1.88727C0.639263 1.39565 0.836455 0.92538 1.1841 0.577739C1.53174 0.230099 2.00201 0.0329074 2.49363 0.0286353C2.98525 0.0243633 3.45888 0.213353 3.8125 0.554899L13.39 10.1311C14.6778 11.4215 15.4011 13.17 15.4011 14.993C15.4011 16.816 14.6778 18.5646 13.39 19.8549L3.80001 29.4449C3.53816 29.7069 3.20456 29.8855 2.84131 29.9581C2.47807 30.0307 2.10145 29.9941 1.75899 29.8529C1.41653 29.7117 1.12357 29.4722 0.917078 29.1647C0.710587 28.8571 0.59982 28.4953 0.598755 28.1249Z"
          fill="black"
        />
      </svg>
    </div>
  );
};

const CustomerSayPage = () => {
  const [testimonials, setTestimonials] = useState([]);
  const [loading, setLoading] = useState(true);

  const getTestimonials = async () => {
    try {
      const response = await axios.get("/api/testimonial/getAll/");
      setTestimonials(response?.data?.testimonial);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getTestimonials();
  }, []);

  //responsive
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
      slidesToSlide: 2, // optional, default to 1.
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  return (
    <>
      <div className="" style={{ marginBottom: "0", paddingBottom: "2rem" }}>
        <h4
          className=" text-center container-title"
          style={{ fontFamily: "Hahmlet", fontWeight: "200" }}
        >
          What Customers Are Saying
        </h4>
        <div className="my-own-custom-container">
          <Carousel
            responsive={responsive}
            arrows={true}
            swipeable={true}
            autoPlay={true}
            infinite={true}
            partialVisible={true}
            focusOnSelect={true}
            renderButtonGroupOutside={true}
            customButtonGroup={<ButtonGroup />}
          >
            {loading && testimonials.length === 0 && (
              <div className="text-center">
                <div className="d-flex justify-content-center mt-5">
                  <div className="spinner-border" role="status">
                    <span className="sr-only"></span>
                  </div>
                </div>
              </div>
            )}

            {!loading && testimonials.length === 0 && (
              <div className="text-center">
                <h5>No Testimonials Found</h5>
              </div>
            )}

            {!loading &&
              testimonials.map((testimonial) => (
                <div className="" key={testimonial._id}>
                  <div
                    className="card mt-5 p-2"
                    style={{ width: "95%", height: "300px" }}
                  >
                    <div className="card-body">
                      <p className="card-text">{testimonial?.testimonial}</p>
                    </div>
                    <div
                      className="card-footer"
                      style={{ alignItems: "center", verticalAlign: "middle" }}
                    >
                      <div className="d-flex ">
                        <div
                          className="mt-1 "
                          style={{ width: "100px", height: "80px" }}
                        >
                          <img
                            className="rounded-circle bg-danger"
                            src={testimonial?.image.url}
                            alt=""
                            width="80px"
                            height="70px"
                          />
                        </div>
                        <div
                          className="mt-1  d-flex align-items-center"
                          style={{ width: "65%" }}
                        >
                          {testimonial?.name}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </Carousel>
        </div>
      </div>
    </>
  );
};

export default CustomerSayPage;
