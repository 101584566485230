import React from "react";
import Policies from "./Policies";

const PrivacyPolicy = () => {
  const title = "Privacy policy";
  const content = (
    <>
      <ol>
        <li>
          {" "}
          <b> Collection of Personally Identifiable Information</b>
          <p>
            {" "}
            We collect email address from you when you select email during your
            registration process. This enables us to sync your wishlist and
            shopping cart already created by you on the JatinMorJewels website.
            We also use your contact information to send you offers and
            recommendations based on your previous orders, your browsing history
            and your interests.
          </p>{" "}
          <p>
            {" "}
            We may disclose to third party services certain personally
            identifiable information listed below:{" "}
            <ul>
              {" "}
              <li>
                information you provide us such as name, email, mobile phone
                number
              </li>
              <li>
                information we collect as you access and use our service,
                including device information, location and network carrier
              </li>
            </ul>{" "}
          </p>
          <p>
            This information is shared with third party service providers so
            that we can:
            <ul>
              {" "}
              <li>personalize the app for you perform behavioral analytics</li>
            </ul>
          </p>
        </li>{" "}
        <li>
          {" "}
          <b>Use of Demographic and Profile Data</b>
          <p>
            {" "}
            We use personal information to provide the services you request. We
            use your personal information to troubleshoot problems; collect fees
            owed; measure interest in our products and services, inform you
            about online and offline offers and customize your experience. In
            our efforts to continually improve our product and service
            offerings, we collect and analyze demographic and profile data about
            our users activity. We identify and use your IP address to help
            diagnose problems with our server, and to administer our website.
          </p>
        </li>{" "}
        <li>
          {" "}
          <b>Security Precautions</b>
          <p>
            {" "}
            Our site has stringent security measures in place to protect the
            loss, misuse, and alteration of the information under our control.
            Whenever you change or access your account information, we offer the
            use of a secure server. Once your information is in our possession
            we adhere to strict security guidelines, protecting it against
            unauthorized access.
          </p>
        </li>{" "}
        <li>
          {" "}
          <b>Choice/Opt-Out</b>
          <p>
            {" "}
            JatinMorJewels.com provides all users with the opportunity to
            opt-out of receiving non-essential (promotional, marketing-related)
            communications from us on behalf of our partners, and from us in
            general, after setting up an account.
          </p>
        </li>{" "}
        <li>
          {" "}
          <b>Questions?</b>
          <p>
            {" "}
            Questions regarding this statement should be directed to the
            following address: info@jatinmorjewels.com
          </p>
        </li>
      </ol>
    </>
  );
  return (
    <div>
      <Policies title={title} content={content} />
    </div>
  );
};

export default PrivacyPolicy;
