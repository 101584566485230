import React, { useState } from "react";
import styled from "styled-components";

// Import your bride images
import bride1 from "../AlIimage/bride1.png";
import bride2 from "../AlIimage/bride2.png";
import bride3 from "../AlIimage/bride3.png";
import bride4 from "../AlIimage/bride4.png";
import bride5 from "../AlIimage/bride5.png";
import bride6 from "../AlIimage/bride6.png";
import bride7 from "../AlIimage/bride7.png";
import bride8 from "../AlIimage/bride8.png";
import bride9 from "../AlIimage/bride9.png";
import bride10 from "../AlIimage/bride10.png";
import bride11 from "../AlIimage/bride11.png";
import bride12 from "../AlIimage/bride12.png";
import { Carousel, Col, Row } from "react-bootstrap";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
`;

const ImageContainer = styled.div`
  display: flex;
  overflow: hidden;
`;

const Image = styled.img`
  width: 300px;
  maxheight: 320px;
  margin: 0 1rem;
`;

const ArrowButton = styled.button`
  font-size: 1.5rem;
  cursor: pointer;
`;

const Bride = () => {
  const brideImages = [
    bride1,
    bride2,
    bride3,
    bride4,
    bride5,
    bride6,
    bride7,
    bride8,
    bride9,
    bride10,
    bride11,
    bride12,
  ];
  const [startIndex, setStartIndex] = useState(0);

  const handlePrevClick = () => {
    const newIndex = Math.max(0, startIndex - 3);
    setStartIndex(newIndex);
  };

  const handleNextClick = () => {
    const newIndex = Math.min(brideImages.length - 3, startIndex + 3);
    setStartIndex(newIndex);
  };

  return (
    <div style={{ marginTop: "-1.0rem", marginBottom: "1rem" }}>
      <span className="title">Brides of Jatin Mor Jewels</span>

      {/* <Container>
        <ArrowButton onClick={handlePrevClick}>&lt;</ArrowButton>
        <ImageContainer>
          {brideImages.slice(startIndex, startIndex + 3).map((image, index) => (
            <Image
              key={index}
              src={image}
              alt={`Bride ${startIndex + index + 1}`}
            />
          ))}
        </ImageContainer>
        <ArrowButton onClick={handleNextClick}>&gt;</ArrowButton>
      </Container> */}
      <Container style={{ marginTop: "0rem" }}>
        <Row style={{ margin: "0rem 1rem" }}>
          <Col>
            <Carousel>
              {brideImages.map((image, index) => (
                <Carousel.Item interval={3000} key={index}>
                  <Row>
                    <Col xs={12} md={4}>
                      <img
                        className="d-block w-100 "
                        src={image}
                        alt={`Bride ${index + 1}`}
                      />
                    </Col>
                    <Col xs={12} md={4} className="d-none d-md-block">
                      <img
                        className="d-block w-100"
                        src={brideImages[(index + 1) % brideImages.length]}
                        alt={`Bride ${index + 2}`}
                      />
                    </Col>
                    <Col xs={12} md={4} className="d-none d-md-block">
                      <img
                        className="d-block w-100"
                        src={brideImages[(index + 2) % brideImages.length]}
                        alt={`Bride ${index + 3}`}
                      />
                    </Col>
                  </Row>
                </Carousel.Item>
              ))}
            </Carousel>
          </Col>
        </Row>
      </Container>
      {/* <Container>
        <Row>
          <Col>
            <Carousel>
              {brideImages.map((image, index) => (
                <Carousel.Item key={index}>
                  <Row>
                    <Col lg={4} md={6} sm={12}>
                      <img
                        className="d-block w-100"
                        src={image}
                        alt={`Bride ${index + 1}`}
                      />
                    </Col>
                    <Col lg={4} md={6} sm={12}>
                      <img
                        className="d-block w-100"
                        src={brideImages[(index + 1) % brideImages.length]}
                        alt={`Bride ${index + 2}`}
                      />
                    </Col>
                    <Col lg={4} md={6} sm={12}>
                      <img
                        className="d-block w-100"
                        src={brideImages[(index + 2) % brideImages.length]}
                        alt={`Bride ${index + 3}`}
                      />
                    </Col>
                  </Row>
                </Carousel.Item>
              ))}
            </Carousel>
          </Col>
        </Row>
      </Container> */}

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <a href="/videocall">
          {/* <img
            src={consultation}
            alt="consult"
            style={{
              paddingLeft: "20%",
              width: "80%",
              borderRadius: "10%",
              cursor: "pointer",
            }}
          /> */}
          <p className="collectionTitle">
            Book a consultation with our experts for a bespoke collection that
            complements <br className="br" /> the essence of your unique
            celebration.
          </p>
        </a>
      </div>
    </div>
  );
};

export default Bride;
