import React from "react";
import { Container } from "react-bootstrap";
import img1 from "../AlIimage/signatureLooksImgs/wetransfer_nazakath_2024-02-02_0654/Copy of 9X4A3355crop20.jpg";
import img2 from "../AlIimage/signatureLooksImgs/wetransfer_nazakath_2024-02-02_0654/Copy of 9X4A3551crop17.jpg";
import img3 from "../AlIimage/signatureLooksImgs/wetransfer_nazakath_2024-02-02_0654/Copy of 9X4A3589crop16.jpg";
import img4 from "../AlIimage/signatureLooksImgs/wetransfer_nazakath_2024-02-02_0654/Copy of 9X4A3647crop15.jpg";
import img5 from "../AlIimage/signatureLooksImgs/wetransfer_nazakath_2024-02-02_0654/Copy of 9X4A3678crop14.jpg";
import img6 from "../AlIimage/signatureLooksImgs/wetransfer_nazakath_2024-02-02_0654/Copy of 9X4A3730crop13.jpg";
import img7 from "../AlIimage/signatureLooksImgs/wetransfer_nazakath_2024-02-02_0654/Copy of 9X4A3730crop13.jpg";
import img8 from "../AlIimage/signatureLooksImgs/wetransfer_nazakath_2024-02-02_0654/Copy of 9X4A3905crop8.jpg";
import img9 from "../AlIimage/signatureLooksImgs/wetransfer_nazakath_2024-02-02_0654/Copy of 9X4A3978crop5.jpg";
import img10 from "../AlIimage/signatureLooksImgs/wetransfer_nazakath_2024-02-02_0654/Copy of 9X4A3998crop4.jpg";
import img11 from "../AlIimage/signatureLooksImgs/wetransfer_nazakath_2024-02-02_0654/Copy of 9X4A4005crop3.jpg";
import img13 from "../AlIimage/signatureLooksImgs/wetransfer_nazakath_2024-02-02_0654/Copy of 9X4A4108crop2.jpg";
import img14 from "../AlIimage/signatureLooksImgs/wetransfer_nazakath_2024-02-02_0654/Copy of 9X4A4189.jpg";

import img16 from "../AlIimage/signatureLooksImgs/wetransfer_nazakath_2024-02-02_0654/Copy of 9X4A4361.jpg";
import img17 from "../AlIimage/signatureLooksImgs/wetransfer_nazakath_2024-02-02_0654/Copy of 9X4A4437.jpg";
import img18 from "../AlIimage/signatureLooksImgs/wetransfer_nazakath_2024-02-02_0654/Copy of 9X4A4442.jpg";
import img19 from "../AlIimage/signatureLooksImgs/wetransfer_nazakath_2024-02-02_0654/Copy of 9X4A4510 copy.jpg";
import img20 from "../AlIimage/signatureLooksImgs/wetransfer_nazakath_2024-02-02_0654/Copy of 9X4A4455.jpg";
import img21 from "../AlIimage/signatureLooksImgs/wetransfer_nazakath_2024-02-02_0654/Copy of 9X4A4584 copy.jpg";
import img22 from "../AlIimage/signatureLooksImgs/wetransfer_nazakath_2024-02-02_0654/Copy of 9X4A4662 copy.jpg";
import img23 from "../AlIimage/signatureLooksImgs/wetransfer_nazakath_2024-02-02_0654/Copy of 9X4A4669 copy.jpg";
import img24 from "../AlIimage/signatureLooksImgs/wetransfer_nazakath_2024-02-02_0654/Copy of 9X4A4741.jpg";
import img25 from "../AlIimage/signatureLooksImgs/wetransfer_nazakath_2024-02-02_0654/Copy of 9X4A4785.jpg";
import img26 from "../AlIimage/signatureLooksImgs/wetransfer_nazakath_2024-02-02_0654/Copy of 9X4A4902.jpg";

import img29 from "../AlIimage/signatureLooksImgs/wetransfer_nazakath_2024-02-02_0654/Copy of Story7.jpg";
import { useMediaQuery } from "react-responsive";

const imagees = [
  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
  // img7,
  img8,
  img9,
  img10,
  img11,
  img13,
  img14,

  img16,
  img17,
  img18,
  img19,
  img20,
  img21,
  img22,
  img23,
  img24,
  img25,
  img26,

  img29,
];

const Nazakath = ({ btnClick }) => {
  const isSmallScreen = useMediaQuery({ maxWidth: 600 });
  return (
    <div style={{ marginTop: "0rem" }}>
      <Container>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <p className="title">Nazakath</p>
          {/* <div style={{ marginTop: "-1rem" }}>
            <button
              onClick={() => {
                window.open(btnClick);
              }}
              
              className="custom-button1"
            >
              {`Click here to shop this look`.toUpperCase()}
            </button>
            
          </div> */}
        </div>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            gap: "1rem",

            margin: "0rem 1rem",
          }}
        >
          {imagees.map((item, i) => (
            <div
              style={{
                position: "relative",
                width: "30%",
                marginBottom: "1rem",
              }}
            >
              <img src={item} alt="nazakath" style={{ width: "100%" }} />
              <i
                onClick={() => {
                  window.open(btnClick);
                }}
                class="far fa-heart"
                style={{
                  position: "absolute",
                  top: "5px",
                  cursor: "pointer",
                  right: "5px",
                  fontSize: isSmallScreen ? "20px" : "34px",
                  color: "#285979",
                }}
              ></i>
            </div>
          ))}
          {/* <img src={img1} width={"30%"} alt="riwaaz" />
          <img src={img2} alt="riwaaz" width={"30%"} />
          <img src={img3} alt="riwaaz" width={"30%"} />
          <img src={img4} alt="riwaaz" width={"30%"} />
          <img src={img1} alt="riwaaz" width={"30%"} /> */}
        </div>
      </Container>
    </div>
  );
};

export default Nazakath;
