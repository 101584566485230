import React from "react";
import Policies from "./Policies";

const TermsOfUse = () => {
  const title = "Terms of Use";
  const content = (
    <>
      <p>
        Please read these terms of use carefully before using this website. If
        you do not agree to this Terms of Use you may not use this Website. By
        using this Website, you signify your explicit assent to this Terms of
        Use as well as the Websites Privacy Policy (which is hereby incorporated
        by reference herein).
      </p>
      <p>
        {" "}
        These Terms of Use sets out the legally binding terms of services
        available on the Website as well as at the terms of use of this Website.
        These Terms of Use along with the Privacy Policy extends to both users,
        who visit the Website but do not transact business on the Website
        ("Users / Guests") as well as users who are registered with by the
        Website to transact business on the Website ("Members").
      </p>
      <p>
        {" "}
        The Company reserves the right to modify or terminate any portion of the
        Website or the Services offered by the Company for any reason, without
        notice and without liability to you or any third party. You are
        responsible for regularly reviewing these Terms of Use so that you will
        be apprised of changes, if any. Nothing in these Terms of Use should be
        construed to confer any rights to third party beneficiaries. This
        Website provides access to pricing, news and other information services
        related to diamonds, precious gems and jewellery.
      </p>
      <p>
        {" "}
        The Website will also feature the recommendations of a trained diamond
        consultant, circulate a list of investment grade diamonds and recommend
        good buys. Recommendations of the consultant are not guaranteed under
        any circumstances and the Company shall not be held liable for any loss
        and / or damage suffered by Members who rely on such recommendation.
      </p>
      <p>
        Some items may appear slightly larger or smaller than actual size due to
        screen defaults and photography techniques. Sometimes the items may be
        represented larger than the actual size in order to clearly show details
        or smaller than the actual size in order to show the entire item. The
        Company shall not be liable for any legal action on this account.
      </p>
    </>
  );
  return (
    <div>
      <Policies title={title} content={content} />
    </div>
  );
};

export default TermsOfUse;
