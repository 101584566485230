import React, { useCallback, useEffect, useState } from "react";
//import "react-modern-calendar-datepicker/lib/DatePicker.css";
import "@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css";
import { Calendar } from "@hassanmojab/react-modern-calendar-datepicker";
import { utils } from "react-modern-calendar-datepicker";

const BookAppointment = () => {
  const today = utils().getToday();
  const [selectedDay, setSelectedDay] = useState(today);
  const [formattedDate, setFormattedDate] = useState("");

  useEffect(() => {
    const getFormattedDate = () => {
      if (!selectedDay) return "";
      const date = new Date(
        selectedDay.year,
        selectedDay.month - 1,
        selectedDay.day
      );
      const formattedDate = date.toLocaleDateString("en-US", {
        weekday: "long",
        month: "long",
        day: "numeric",
        year: "numeric",
      });

      setFormattedDate(formattedDate);
    };
    getFormattedDate();
  }, [selectedDay]);

  return (
    <div className="conatiner" style={{ margin: "5%" }}>
      <div
        className="card"
        style={{
          margin: "auto",
          width: "90%",
          padding: "5%",
        }}
      >
        <div className="card-body">
          <h2
            className="text-center container-title"
            style={{ marginTop: "0", fontSize: "40px" }}
          >
            Book An Appointment
          </h2>

          <form>
            <div className="form-group mb-4">
              <label for="name">Name</label>
              <input
                type="text"
                className="form-control"
                id="name"
                aria-describedby="name"
                placeholder="Enter your name here"
              />
            </div>

            <div className="form-group mb-4 ">
              <label htmlfor="date">Date and time</label>
              <br />
              <div className="row">
                <div className="col-md-8">
                  <h4>Select a Date & time</h4>

                  <br />
                  <Calendar
                    value={selectedDay}
                    onChange={setSelectedDay}
                    shouldHighlightWeekends
                    colorPrimary="#0fbcf9"
                    calendarClassName="responsive-calendar"
                    minimumDate={utils().getToday()}
                  />
                </div>
                <div className="col-md-4">
                  <br />
                  {selectedDay && (
                    <div className="row">
                      <div className="col-md-12">
                        {formattedDate && (
                          <h6 className="text-left">{formattedDate}</h6>
                        )}
                      </div>
                    </div>
                  )}
                  <br />
                  <div
                    className="row overflow-auto"
                    style={{
                      margin: "auto",
                      justifyContent: "center",
                      overflowY: "scroll",
                      height: "350px",
                      margin: "auto",
                      textAlign: "center",
                    }}
                  >
                    <div className="col-md-12 p-2 mt-1">
                      <button className="btn btn-outline-primary w-100 p-2">
                        10:00 AM
                      </button>
                    </div>
                    <div className="col-md-12 p-2 mt-1 ">
                      <button className="btn btn-outline-primary w-100 p-2">
                        11:00 AM
                      </button>
                    </div>
                    <div className="col-md-12 p-2 mt-1">
                      <button className="btn btn-outline-primary w-100 p-2">
                        12:00 PM
                      </button>
                    </div>
                    <div className="col-md-12 p-2 mt-1">
                      <button className="btn btn-outline-primary w-100 p-2">
                        01:00 PM
                      </button>
                    </div>
                    <div className="col-md-12 p-2 mt-1">
                      <button className="btn btn-outline-primary w-100 p-2">
                        02:00 PM
                      </button>
                    </div>
                    <div className="col-md-12 p-2 mt-1">
                      <button className="btn btn-outline-primary w-100 p-2">
                        03:00 PM
                      </button>
                    </div>
                    <div className="col-md-12 p-2 mt-1">
                      <button className="btn btn-outline-primary w-100 p-2">
                        04:00 PM
                      </button>
                    </div>
                    <div className="col-md-12 p-2 mt-1">
                      <button className="btn btn-outline-primary w-100 p-2">
                        05:00 PM
                      </button>
                    </div>
                    <div className="col-md-12 p-2 mt-1">
                      <button className="btn btn-outline-primary w-100 p-2">
                        06:00 PM
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="form-group mb-4">
              <label for="email">Email</label>
              <input
                type="email"
                className="form-control"
                id="email"
                aria-describedby="email"
                placeholder="Enter your email here"
              />
            </div>

            <button
              type="submit"
              className="btn text-white product-button"
              style={{ margin: "auto", width: "200px", maxWidth: "90%" }}
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default BookAppointment;
