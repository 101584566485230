import React from "react";
import Catalogue from "../Catalogue";
import { Link, useParams } from "react-router-dom";
import axios from "axios";

const ProductCategories = () => {
  const [loading, setLoading] = React.useState(true);
  const [productCategory, setProductCategory] = React.useState([]);
  const { category } = useParams();

  const getProductCategory = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `/api/productCategory/getOne/${category}`
      );

      setProductCategory(response.data.productCategory);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  React.useEffect(() => {
    getProductCategory();
  }, [category]);

  const titleText = (
    <div>
      <p>{productCategory.description}</p>
    </div>
  );

  return (
    <>
      {loading && <div>Loading...</div>}
      {!loading && productCategory && (
        <div>
          <Catalogue
            title={productCategory.name}
            titleText={titleText}
            titleImage={productCategory.image[0].url}
            category={productCategory.name}
            productCollection={""}
          />
        </div>
      )}
    </>
  );
};

export default ProductCategories;
