import React from "react";
import { Container } from "react-bootstrap";
import img1 from "../AlIimage/signatureLooksImgs/wetransfer_jharoka_2024-02-02_0647/Copy of Untitled Session0521.jpg";
import img2 from "../AlIimage/signatureLooksImgs/wetransfer_jharoka_2024-02-02_0647/Copy of Untitled Session1075.jpg";
import img3 from "../AlIimage/signatureLooksImgs/wetransfer_jharoka_2024-02-02_0647/Copy of Untitled Session1092 copy.jpg";
import img4 from "../AlIimage/signatureLooksImgs/wetransfer_jharoka_2024-02-02_0647/Copy of Untitled Session1101.jpg";
import img5 from "../AlIimage/signatureLooksImgs/wetransfer_jharoka_2024-02-02_0647/Copy of Untitled Session2672.jpg";
import img6 from "../AlIimage/signatureLooksImgs/wetransfer_jharoka_2024-02-02_0647/Copy of Untitled Session3745.jpg";
import img7 from "../AlIimage/signatureLooksImgs/wetransfer_jharoka_2024-02-02_0647/Copy of Untitled Session5209.jpg";
import img8 from "../AlIimage/signatureLooksImgs/wetransfer_jharoka_2024-02-02_0647/Copy of Untitled Session5237.jpg";
import img9 from "../AlIimage/signatureLooksImgs/wetransfer_jharoka_2024-02-02_0647/Copy of Untitled Session5808.jpg";
import img10 from "../AlIimage/signatureLooksImgs/wetransfer_jharoka_2024-02-02_0647/Copy of Untitled Session5848.jpg";
import img11 from "../AlIimage/signatureLooksImgs/wetransfer_jharoka_2024-02-02_0647/Copy of Untitled Session5861.jpg";
import img13 from "../AlIimage/signatureLooksImgs/wetransfer_jharoka_2024-02-02_0647/Copy of Untitled Session6923.jpg";
import img14 from "../AlIimage/signatureLooksImgs/wetransfer_jharoka_2024-02-02_0647/Copy of Untitled Session7343 copy.jpg";
import img15 from "../AlIimage/signatureLooksImgs/wetransfer_jharoka_2024-02-02_0647/Copy of Untitled Session9420.jpg";
import img16 from "../AlIimage/signatureLooksImgs/wetransfer_jharoka_2024-02-02_0647/Copy of Untitled Session9423.jpg";
import img17 from "../AlIimage/signatureLooksImgs/wetransfer_jharoka_2024-02-02_0647/Copy of Untitled Session9432.jpg";
import img18 from "../AlIimage/signatureLooksImgs/wetransfer_jharoka_2024-02-02_0647/Copy of Untitled Session9473.jpg";
import img19 from "../AlIimage/signatureLooksImgs/wetransfer_jharoka_2024-02-02_0647/Copy of jmj2160.jpg";
import img20 from "../AlIimage/signatureLooksImgs/wetransfer_jharoka_2024-02-02_0647/Copy of jmj2192.jpg";
import img21 from "../AlIimage/signatureLooksImgs/wetransfer_jharoka_2024-02-02_0647/Copy of jmj2660.jpg";
import img22 from "../AlIimage/signatureLooksImgs/wetransfer_jharoka_2024-02-02_0647/Copy of jmj3715.jpg";

import img24 from "../AlIimage/signatureLooksImgs/wetransfer_jharoka_2024-02-02_0647/Copy of jmj4292.jpg";
import img25 from "../AlIimage/signatureLooksImgs/wetransfer_jharoka_2024-02-02_0647/Copy of jmj5788.jpg";
import img26 from "../AlIimage/signatureLooksImgs/wetransfer_jharoka_2024-02-02_0647/Copy of jmj5815.jpg";
import img27 from "../AlIimage/signatureLooksImgs/wetransfer_jharoka_2024-02-02_0647/Copy of jmj7410.jpg";
import img28 from "../AlIimage/signatureLooksImgs/wetransfer_jharoka_2024-02-02_0647/Copy of jmj7437.jpg";
import img29 from "../AlIimage/signatureLooksImgs/wetransfer_jharoka_2024-02-02_0647/Copy of jmj9541.jpg";
import { useMediaQuery } from "react-responsive";
// import img30 from "../AlIimage/signatureLooksImgs/wetransfer_jharoka_2024-02-02_0647/";

const imagees = [
  img2,
  img3,
  img4,
  img5,

  img7,
  img6,

  img9,

  img10,

  img13,
  img1,
  img14,
  img15,
  // img16,
  img17,
  img18,

  img26,
  img21,
  img22,

  img24,
  img20,
  img29,
  // img25,

  img19,
  img27,
  img28,
];

const Jharokha = ({ btnClick }) => {
  const isSmallScreen = useMediaQuery({ maxWidth: 600 });
  return (
    <div style={{ marginTop: "0rem" }}>
      <Container>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <p className="title">Jharokha</p>
          {/* <div style={{ marginTop: "-1rem" }}>
            <button
              onClick={() => {
                window.open(btnClick);
              }}
              
              className="custom-button1"
            >
              {`Click here to shop this look`.toUpperCase()}
            </button>
            
          </div> */}
        </div>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            gap: "1rem",
            margin: "0rem 1rem",
          }}
        >
          {imagees.map((item, i) => (
            <div
              style={{
                position: "relative",
                width: "30%",
                marginBottom: "1rem",
              }}
            >
              <img src={item} alt="nazakath" style={{ width: "100%" }} />
              <i
                onClick={() => {
                  window.open(btnClick);
                }}
                class="far fa-heart"
                style={{
                  position: "absolute",
                  top: "5px",
                  cursor: "pointer",
                  right: "5px",
                  fontSize: isSmallScreen ? "20px" : "34px",
                  color: "#285979",
                }}
              ></i>
            </div>
          ))}
        </div>
      </Container>
    </div>
  );
};

export default Jharokha;
