import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import { EffectCoverflow, Pagination } from "swiper/modules";
import { Link } from "react-router-dom";

export default function ImageSlider({ ourCollections }) {
  // Function to duplicate the first and last slides
  // const duplicateSlides = (slides) => {
  //   const duplicatedSlides = [slides[slides.length - 1], ...slides, slides[3]];
  //   return duplicatedSlides;
  // };

  return (
    <>
      <Swiper
        effect={"coverflow"}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={"auto"}
        coverflowEffect={{
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        }}
        pagination={{
          clickable: true,
        }}
        autoplay={{
          delay: 2500, // milliseconds between each slide
          disableOnInteraction: false, // don't stop autoplay on user interaction
        }}
        spaceBetween={50} // Adjust as needed
        loop={true} // Infinite loop
        modules={[EffectCoverflow, Pagination]}
        className="mySwiper"
      >
        {/* Duplicate the first and last slides */}
        {ourCollections &&
          ourCollections.map((collection, index) => (
            <SwiperSlide style={{ height: "400px" }} key={index}>
              <Link
                to={`/collection/${
                  collection?.name.charAt(0).toUpperCase() +
                  collection?.name.slice(1)
                }`}
              >
                <div>
                  <p
                    style={{
                      backgroundColor: "#010d1b",
                      color: "white",
                      padding: "4px",
                      fontWeight: "600",
                      fontSize: "1.2rem",
                      fontFamily: "Raleway",
                      textAlign: "center",
                    }}
                  >
                    {collection?.name}
                  </p>
                  <img
                    src={collection?.image[0].url}
                    alt={collection?.name}
                    style={{
                      width: "100%",
                      height: "380px",
                      objectFit: "containe",
                    }}
                  />
                </div>
                {/* <div style={{ position: "relative" }}>
                  
                  <p
                    style={{
                      position: "absolute",

                      left: "50%",
                      width: "100%",
                      transform: "translateX(-50%)",
                      backgroundColor: "#010d1b",
                      color: "white",
                      padding: "8px",
                      fontWeight: "bold",
                      fontSize: "1.5rem",
                      fontFamily: "Felix Titling",
                      
                      textAlign: "center",
                    }}
                  >
                    {collection.name}
                  </p>
                 
                  <div
                    className="item"
                    style={{
                      flex: "1 0 30%",
                      display: "flex",
                      marginBottom: "8px",
                    }}
                  >
                    <img
                      src={collection.image[0].url}
                      alt={collection.name}
                      style={{
                        width: "100%",
                        height: "360px",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                </div> */}
              </Link>
            </SwiperSlide>
          ))}
      </Swiper>
    </>
  );
}
